import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[rhDdragAndDropFiles]',
    standalone: true
})
export class DragAndDropFilesDirective {
  @Output() filesDropped = new EventEmitter<FileList>();
  @Output() activeChange = new EventEmitter<boolean>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.activeChange?.emit(true);
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.activeChange?.emit(false);
  }

  // Drop listener
  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.activeChange?.emit(false);
    const files = evt.dataTransfer?.files;
    if (files && files.length) {
      this.filesDropped.emit(files);
    }
  }
}
