import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PlaceholderBaseComponent } from './placeholder-base.component';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'rh-url-based-placeholder-base',
  template: '',
})
export abstract class UrlBasedPlaceholderBaseComponent extends PlaceholderBaseComponent {
  protected _displayUrl!: string;

  @Input()
  public set url(value: string) {
    this.updateDisplayUrl(value);
  }

  @Output() public urlChange: EventEmitter<string> = new EventEmitter<string>();

  public onChangeUrl() {
    this.urlChange?.emit('');
  }

  public get isValidMediaAsset(): boolean {
    const isBase64Asset = this._displayUrl.startsWith('data:image');
    const isPredefinedAsset = this._displayUrl.indexOf('assets/img') !== -1;
    return (
      isBase64Asset ||
      isPredefinedAsset ||
      (this._displayUrl?.split('/').pop()?.includes('.') ?? false)
    );
  }

  private updateDisplayUrl(value: string) {
    const isNotPredefinedAsset = value.indexOf('assets/img') === -1;
    const isBase64Asset = value.startsWith('data:image');
    const isHttpResource = value.startsWith('http');
    const isEmpty = !value;

    this._displayUrl =
      isNotPredefinedAsset && !isBase64Asset && !isHttpResource && !isEmpty
        ? `${environment.backendApiUrl}/blobAssets/${value}`
        : value;
  }
}
