import {
  Component,
  HostListener,
  inject,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  debounceTime,
  distinctUntilChanged,
  from,
  lastValueFrom,
  map,
  Subject,
  Subscription,
} from 'rxjs';
import { CheckboxComponent } from '../../../core/components/checkbox/checkbox.component';
import { ConfirmCancelDialogComponent } from '../../../core/components/confirm-cancel-dialog/confirm-cancel-dialog.component';
import { IConfirmCancelDialogData } from '../../../core/components/confirm-cancel-dialog/core/confirm-cancel-dialog-data.interface';
import { IConfirmationResult } from '../../../core/components/confirm-cancel-dialog/core/confirmation-result.model';
import { DropdownComponent } from '../../../core/components/dropdown/dropdown.component';
import { TextboxComponent } from '../../../core/components/textbox/textbox.component';
import { MediaTypeEnum } from '../../../core/enums/media-type.enum';
import { Category } from '../../../core/models/category.model';
import { MediaAsset } from '../../../core/models/media-asset.model';
import { MediaTile } from '../../../core/models/media-tile.model';
import { User } from '../../../core/models/user.model';
import { DialogRef } from '../../../core/services/dialog/dialog-ref';
import { DIALOG_DATA } from '../../../core/services/dialog/dialog-tokens';
import { DialogService } from '../../../core/services/dialog/dialog.service';
import { SnackbarType } from '../../../core/services/snackbar/core/snackbar-type.enum';
import { SnackbarComponent } from '../../../core/services/snackbar/snackbar.component';
import { SnackbarService } from '../../../core/services/snackbar/snackbar.service';
import { selectCategories } from '../../../shared/store/category/category.selectors';
import {
  setLoadingActive,
  setLoadingInactive,
} from '../../../shared/store/core/core.actions';
import {
  clearMediaMessages,
  clearTemporaryMediaAssetsUrlUpdate,
  deleteMediaAsset,
  deleteTemporaryMediaAsset,
  loadMediaAssets,
  loadMediaEditors,
  saveMediaAssets,
} from '../../../shared/store/media/media.actions';
import {
  selectAvailableEditorsAsUsers,
  selectMediaAssets,
  selectMediaError,
  selectMediaProgress,
  selectTemporaryMediaAssets,
} from '../../../shared/store/media/media.selectors';
import { selectUser } from '../../../shared/store/user/user.selectors';
import { MediaDragAndDropAreaComponent } from '../media-drag-and-drop-area/media-drag-and-drop-area.component';
import { MediaMetadataViewerComponent } from '../media-metadata-viewer/media-metadata-viewer.component';
import { MediaPreviewModalComponent } from '../media-preview-modal/media-preview-modal.component';
import { MediaTileComponent } from '../media-tile/media-tile.component';
import { IMediaModalData } from './media-modal-data.interface';

@Component({
  selector: 'rh-media-library-modal',
  templateUrl: './media-library-modal.component.html',
  styleUrls: ['./media-library-modal.component.scss'],
  standalone: true,
  imports: [
    DropdownComponent,
    TextboxComponent,
    CheckboxComponent,
    MediaDragAndDropAreaComponent,
    MediaTileComponent,
    MediaMetadataViewerComponent,
    TranslateModule,
  ],
})
export class MediaLibraryModalComponent implements OnInit, OnDestroy {
  private dialogRef = inject(DialogRef);
  private store = inject(Store);
  private dialog = inject(DialogService);
  private translator = inject(TranslateService);
  private snackbar = inject(SnackbarService);

  private subscriptions: Subscription = new Subscription();

  private tempAssetsUpdate$ = this.store.select(selectTemporaryMediaAssets);
  private loadAssets$ = this.store.select(selectMediaAssets);
  private mediaAssetSavingProgress$ = this.store.select(selectMediaProgress);
  private errorSavingAssets$ = this.store.select(selectMediaError);

  public galleryView = true;
  public editMode = false;
  public MediaTypeEnums = MediaTypeEnum;
  public selectedMediaType?: { type: MediaTypeEnum; name: string };
  public searchText = '';
  public searchTextChange$ = new Subject();
  public selectedCategory?: Category;
  public selectedTimeFrame?: { value: number; name: string };
  public selectedEditor?: User;
  public showOnlyPrivate = false;

  private availableCategories$ = this.store.select(selectCategories);
  public availableCategories?: Category[];
  public availableFilterCategories?: Category[];
  private availableEditors$ = this.store.select(selectAvailableEditorsAsUsers);
  public availableEditors: User[] = [];
  private user$ = this.store.select(selectUser);
  public user?: User;

  public sortByOptions = [
    { value: 'DATE', name: 'media.filters.sortBy.date' },
    { value: 'NAME', name: 'media.filters.sortBy.name' },
  ];
  public sortBy = this.sortByOptions[0];

  public sortDirOptions = [
    { value: 'DESC', name: 'media.filters.sortDir.desc' },
    { value: 'ASC', name: 'media.filters.sortDir.asc' },
  ];
  public sortDir = this.sortDirOptions[0];

  public tiles: MediaTile[] = [];

  public tempUploadedTiles: MediaTile[] = [];

  constructor(@Inject(DIALOG_DATA) public data: IMediaModalData) {
    this.selectedMediaType = { type: data.mediaType, name: data.mediaType };
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
      event.stopImmediatePropagation();
      return false;
    }

    return true;
  }

  public async ngOnInit(): Promise<void> {
    this.subscriptions.add(
      this.availableCategories$?.subscribe(async (categories) => {
        if (!categories || categories.length <= 0) {
          return;
        }

        this.availableCategories = categories;

        const allItem = {
          id: 0,
          name: await lastValueFrom(
            this.translator.get('media.filters.unselectedText'),
          ),
        } as Category;

        this.availableFilterCategories = [allItem, ...this.availableCategories];
      }),
    );

    this.subscriptions.add(
      this.availableEditors$?.subscribe(async (editors: User[]) => {
        if (!editors || !editors.length) {
          return;
        }

        this.availableEditors = editors;
        this.availableEditors.unshift({
          name: await lastValueFrom(
            this.translator.get('media.filters.unselectedText'),
          ),
          accountName: '',
        } as User);
      }),
    );

    this.subscriptions.add(
      this.tempAssetsUpdate$?.subscribe((update: MediaAsset[]) => {
        if (!update) {
          return;
        }

        this.store.dispatch(setLoadingInactive());

        // Prepare the saved blobs/media for metadata/confnirmation view
        this.tempUploadedTiles = [];
        for (let i = 0; i < update.length; i++) {
          this.tempUploadedTiles.push({
            id: update[i].id,
            title: update[i].assetName,
            categoryId: update[i].categoryId,
            author: update[i].editor,
            uploaded: update[i].uploaded,
            private: update[i].private,
            mimeType: update[i].mimeType,
            imageUrl: update[i].assetUrl,
            displayImageUrl: update[i].assetDisplayUrl,
            enable: true,
          } as MediaTile);
        }

        this.galleryView = this.tempUploadedTiles.length <= 0;
        this.editMode = false;
      }),
    );

    this.subscriptions.add(
      this.mediaAssetSavingProgress$?.subscribe((progress) => {
        if (progress === 100) {
          this.loadAssets();
        }
      }),
    );

    this.subscriptions.add(
      this.loadAssets$?.subscribe((assets) => {
        this.tiles = assets;

        this.store.dispatch(setLoadingInactive());
      }),
    );

    this.subscriptions.add(
      this.user$.subscribe((user) => {
        this.user = user;
        this.selectedEditor = {
          name: user?.email,
          accountName: user?.email,
        } as User;
      }),
    );

    // Searchtext change detection
    this.subscriptions.add(
      this.searchTextChange$
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          map(() => from(this.loadAssets())),
        )
        .subscribe(),
    );

    this.subscriptions.add(
      this.errorSavingAssets$?.subscribe((error) => {
        if (error) {
          this.store.dispatch(clearMediaMessages());
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `snackbar.mediaAssetOperationFailed`,
          });
        }
      }),
    );

    this.loadAssets();
  }

  public async onDeleteRequested(tile: MediaTile) {
    const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
      data: {
        title: await lastValueFrom(
          this.translator.get('media.delete.confirmationTitle'),
        ),
        text: await lastValueFrom(
          this.translator.get('media.delete.confirmationText'),
        ),
      } as IConfirmCancelDialogData,
    });

    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result.decision) {
      return;
    }

    this.store.dispatch(setLoadingActive());
    this.store.dispatch(deleteMediaAsset({ id: tile.id }));
  }

  public getMediaType(mimeType: string): MediaTypeEnum {
    if (mimeType.indexOf('image') > -1) {
      return MediaTypeEnum.Image;
    } else if (mimeType.indexOf('video') > -1) {
      return MediaTypeEnum.Video;
    } else if (mimeType.indexOf('slide') > -1) {
      return MediaTypeEnum.Slide;
    }

    return MediaTypeEnum.Image;
  }

  public async onEditRequested(tile: MediaTile) {
    tile.category = this.availableCategories?.filter(
      (c) => c.id === tile.categoryId,
    )[0];
    this.tempUploadedTiles = [JSON.parse(JSON.stringify(tile)) as MediaTile];
    this.galleryView = this.tempUploadedTiles.length <= 0;
    this.editMode = true;
  }

  public async onViewRequested(tile: MediaTile) {
    const dialogRef = this.dialog.open(
      MediaPreviewModalComponent,
      {
        data: { url: tile.displayImageUrl },
      },
      'custom-overlay-panel-dark',
    );

    await lastValueFrom(dialogRef.afterClosed());
  }

  public async onDeclineRequested(mediaAssetId: number) {
    this.store.dispatch(
      deleteTemporaryMediaAsset({ mediaAssetId: mediaAssetId }),
    );
  }

  public async onConfirmationDone(selectedTiles: MediaTile[]) {
    this.galleryView = true;
    this.store.dispatch(clearTemporaryMediaAssetsUrlUpdate());

    if (!selectedTiles.length) {
      return;
    }

    const assets = selectedTiles.map((t) => {
      return {
        id: t.id,
        assetName: t.title,
        assetUrl: t.imageUrl,
        assetDisplayUrl: t.displayImageUrl,
        mimeType: t.mimeType,
        categoryId: t.categoryId,
        editor: '',
        uploaded: undefined,
        private: t.private,
      } as MediaAsset;
    });

    this.store.dispatch(saveMediaAssets({ assets }));
  }

  public async close(): Promise<void> {
    this.store.dispatch(clearTemporaryMediaAssetsUrlUpdate());
    this.dialogRef.close();
  }

  public async select(tile: MediaTile): Promise<void> {
    if (!this.data.enableSelection) {
      return;
    }

    this.store.dispatch(clearTemporaryMediaAssetsUrlUpdate());
    this.dialogRef.close({
      type: tile.mimeType,
      payload: {
        name: tile.title,
        url: tile.imageUrl,
      },
    } as IConfirmationResult);
  }

  public isCreator(author: string): boolean {
    return author === this.user?.email;
  }

  public async ngOnDestroy(): Promise<void> {
    this.subscriptions?.unsubscribe();
  }

  public async onSearchTextChanged() {
    this.searchTextChange$.next(this.searchText);
  }

  public async loadAssets(): Promise<void> {
    this.store.dispatch(setLoadingActive());
    this.store.dispatch(
      loadMediaAssets({
        mediaType: this.selectedMediaType?.type || '',
        searchText: this.searchText,
        categoryId: this.selectedCategory?.id || 0,
        timeFrame: this.selectedTimeFrame?.value || 0,
        editorId: this.selectedEditor?.accountName || '',
        privateOnly: this.showOnlyPrivate,
        sortBy: this.sortBy.value,
        sortDir: this.sortDir.value,
      }),
    );
    this.store.dispatch(loadMediaEditors());
  }
}
