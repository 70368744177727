import {
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { DIALOG_DATA } from '../../../core/services/dialog/dialog-tokens';
import { DialogRef } from '../../services/dialog/dialog-ref';
import { ConfirmCancelCommentFieldComponent } from './core/confirm-cancel-comment-field/confirm-cancel-comment-field.component';
import { IConfirmCancelDialogData } from './core/confirm-cancel-dialog-data.interface';
import { ConfirmCancelToolbarComponent } from './core/confirm-cancel-toolbar/confirm-cancel-toolbar.component';
import { IConfirmationResult } from './core/confirmation-result.model';

import { ConfirmCancelHeaderComponent } from './core/confirm-cancel-header/confirm-cancel-header.component';

@Component({
  selector: 'rh-confirm-cancel-dialog',
  templateUrl: './confirm-cancel-dialog.component.html',
  styleUrls: ['./confirm-cancel-dialog.component.scss'],
  standalone: true,
  imports: [
    ConfirmCancelHeaderComponent,
    ConfirmCancelCommentFieldComponent,
    ConfirmCancelToolbarComponent,
  ],
})
export class ConfirmCancelDialogComponent {
  private dialogRef = inject(DialogRef);

  @Input() public title!: string;
  @Input() public text!: string;
  @Input() public requireComment!: boolean;
  @Input() public confirmationButtonText!: string;
  @Input() public comment = '';

  @Output() public confirmChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public get confirmButtonEnabled(): boolean {
    return !this.requireComment || (!!this.comment && this.comment.length > 0);
  }

  constructor(@Inject(DIALOG_DATA) public data: IConfirmCancelDialogData) {
    if (data) {
      this.title = data.title;
      this.text = data.text;
      this.confirmationButtonText = data.confirmationButtonText;
      this.requireComment = data.requireComment;
    }
  }

  public handleEvent(val: boolean) {
    this.dialogRef.close({
      decision: val,
      comment: this.comment,
    } as IConfirmationResult);
  }
}
