import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Slide } from '../../../core/models/slide.model';

@Injectable({
  providedIn: 'root',
})
export class SlideService {
  private http = inject(HttpClient);

  AddSlide(slide: Slide, courseId: number): Observable<{ slideId: number }> {
    return this.http
      .post<{
        slideId: number;
      }>(
        `${environment.backendApiUrl}/AddSlide?courseId=${courseId}&code=${environment.apiKey}`,
        { slide: slide },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveSlides(
    slides: Slide[],
    courseId: number,
    thumbnail?: string,
    thumbnailSlideId?: number,
  ): Observable<{ slides: number[] }> {
    return this.http
      .post<{
        slides: number[];
      }>(
        `${environment.backendApiUrl}/SaveSlides?courseId=${courseId}&code=${environment.apiKey}`,
        { slides, thumbnail, thumbnailSlideId },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  PasteSlide(
    slideId: number,
    courseVersionId: number,
    position: number,
  ): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/PasteSlide?code=${environment.apiKey}`,
        { courseVersionId, slideId, position },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteSlide(slide: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteSlide?slideId=${slide}&code=${environment.apiKey}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
