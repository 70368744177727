import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { SnackbarType } from '../../../core/services/snackbar/core/snackbar-type.enum';
import { SnackbarComponent } from '../../../core/services/snackbar/snackbar.component';
import { SnackbarService } from '../../../core/services/snackbar/snackbar.service';
import { CategoryService } from '../../services/category/category.service';
import {
  deleteCategory,
  deleteCategoryFailure,
  deleteCategorySuccess,
  deleteSubcategory,
  deleteSubcategoryFailure,
  deleteSubcategorySuccess,
  loadCategories,
  loadCategoriesFailure,
  loadCategoriesSuccess,
  saveCategory,
  saveCategoryFailure,
  saveCategorySuccess,
  syncCategories,
  syncCategoriesFailure,
  syncCategoriesSuccess,
} from './category.actions';

@Injectable()
export class CategoryEffects {
  private categoryService = inject(CategoryService);
  private actions$ = inject(Actions);
  private snackbar = inject(SnackbarService);

  syncCategoriesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncCategories),
      exhaustMap(() =>
        this.categoryService.SyncCategories().pipe(
          map(() => syncCategoriesSuccess()),
          catchError((error) =>
            observableOf(syncCategoriesFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  syncCategoriesSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncCategoriesSuccess),
      map(() => loadCategories()),
    ),
  );

  saveCategoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveCategory),
      exhaustMap((action) =>
        this.categoryService.SaveCategory(action.category).pipe(
          map(() => saveCategorySuccess()),
          catchError((error) =>
            observableOf(saveCategoryFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  deleteCategoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCategory),
      exhaustMap((action) =>
        this.categoryService.DeleteCategory(action.id).pipe(
          map(() => deleteCategorySuccess()),
          catchError((error) =>
            observableOf(deleteCategoryFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  deleteSubcategoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSubcategory),
      exhaustMap((action) =>
        this.categoryService.DeleteSubcategory(action.id).pipe(
          map(() => deleteSubcategorySuccess()),
          catchError((error) =>
            observableOf(deleteSubcategoryFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  saveCategorySuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveCategorySuccess),
      map(() => loadCategories()),
    ),
  );

  deleteCategorySuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCategorySuccess),
      map(() => loadCategories()),
    ),
  );

  deleteSubcategorySuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSubcategorySuccess),
      map(() => loadCategories()),
    ),
  );

  loadCategoriesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCategories),
      exhaustMap(() =>
        this.categoryService.GetCategories().pipe(
          map((items) => loadCategoriesSuccess({ categories: items })),
          catchError((error) =>
            observableOf(loadCategoriesFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  loadCategoriesFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadCategoriesFailure),
        tap((action) => {
          console.error(action.error);
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `snackbar.loadCategoriesFailure`,
          });
        }),
      ),
    { dispatch: false },
  );
}
