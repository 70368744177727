<div class="coverchooser-container">
  <div class="title-row">
    <div class="title">Choose a Cover</div>
    <rh-button
      [imageUrl]="'/assets/img/buttons/upload.svg'"
      text="Upload Image"
      type="click"
      [darkMode]="true"
      (click)="uploadImage()"
    ></rh-button>
  </div>
  <div class="content">
    @for (c of covers; track c.id) {
      <span
        class="thumbnail-container"
        [ngClass]="{ selected: c === currentSelection }"
        (click)="select(c)"
      >
        <img [src]="c.templateImageUrl" alt="Cover thumbnail" />
      </span>
    }
  </div>
</div>

<input
  #file
  class="input"
  type="file"
  accept="image/*"
  (change)="onFileChanged($event)"
/>
