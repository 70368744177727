<div class="dropdown-container" [ngClass]="{ 'blue-mode': blueMode }">
  @if (controlTitle) {
    <div
      class="control-title text-[11px] font-brand-regular text-brand-blue-400 uppercase"
    >
      {{ controlTitle | translate }}
    </div>
  }
  <div class="dropdown-container-inner">
    <input
      [ngClass]="
        model
          ? 'dropdown-input'
          : 'dropdown-input' + (invalidIfEmpty ? ' invalid' : '')
      "
      class="font-brand-regular text-lg"
      type="text"
      spellcheck="false"
      [value]="model?.name || '' | translate"
      [disabled]="disabled"
      (click)="toggle()"
      (focusout)="onFocusOutEvent()"
    />
    @if (listVisible) {
      <div class="options-list font-brand-regular text-lg">
        @for (item of items; track item) {
          <div
            [ngClass]="{ selected: isSelected(item) }"
            class="option"
            [title]="item.name | translate"
            (click)="select(item)"
          >
            {{ item.name | translate }}
          </div>
        }
      </div>
    }

    <div class="item-selector">
      <div class="svg-container">
        @if (blueMode) {
          <img
            [ngClass]="{ 'rotated-arrow': listVisible }"
            src="./assets/img/buttons/dropdown-white.svg"
          />
        } @else {
          <img
            [ngClass]="{ 'rotated-arrow': listVisible }"
            src="./assets/img/buttons/dropdown.svg"
          />
        }
      </div>
    </div>
  </div>
</div>
