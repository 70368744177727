<div [ngClass]="zoomed ? 'toolbar-zoomed' : 'toolbar'">
  <span class="buttons">
    @if (zoomed) {
      <rh-icon
        (click)="toggleZoom()"
        [imageUrl]="'./assets/img/Reishauer_Icons_Close_Blue.svg'"
        class="button"
      ></rh-icon>
    }
    @if (showZoom && !zoomed) {
      <rh-icon
        (click)="toggleZoom()"
        [imageUrl]="'./assets/img/Reishauer_Icons_Zoom.svg'"
        class="button"
      ></rh-icon>
    }
    @if (editMode && !zoomed) {
      <rh-icon
        [ngClass]="{ 'button-disabled': !enableDelete }"
        (click)="remove()"
        [imageUrl]="'/assets/img/Reishauer_Icons_Remove.svg'"
        class="button"
      ></rh-icon>
      <rh-icon
        (click)="add()"
        [imageUrl]="'/assets/img/Reishauer_Icons_Add.svg'"
        class="button"
      ></rh-icon>
    }
  </span>
</div>
