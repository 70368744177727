import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SlideState } from './slide.state';

const selectSlideState = createFeatureSelector<SlideState>('slide');

export const selectSlideProgressUpdate = createSelector(
  selectSlideState,
  (state) => state.progressUpdate,
);

export const selectSlideUrlUpdate = createSelector(
  selectSlideState,
  (state) => state.urlUpdate,
);

export const selectIsSlideFocused = createSelector(
  selectSlideState,
  (state) => state.slideFocused,
);
