import { Observable, of } from 'rxjs';
import {
  ROLE_ADMIN,
  ROLE_ADMIN_KEY,
  ROLE_APPROVER,
  ROLE_APPROVER_KEY,
  ROLE_EDITOR,
  ROLE_EDITOR_KEY,
  ROLE_VIEWER,
  ROLE_VIEWER_KEY,
} from '../../../constants/roles.constants';
import { Permission } from '../../../models/permission.model';
import { Role } from '../../../models/role.model';
import { PermissionsService } from '../permissions.service';

export class PermissionsServiceMock extends PermissionsService {
  private readerUserRoles: Role[] = [
    { key: ROLE_VIEWER_KEY, name: ROLE_VIEWER } as Role,
  ];

  private editorUserRoles: Role[] = [
    { key: ROLE_EDITOR_KEY, name: ROLE_EDITOR } as Role,
  ];

  private approverUserRoles: Role[] = [
    { key: ROLE_APPROVER_KEY, name: ROLE_APPROVER } as Role,
  ];

  private adminUserRoles: Role[] = [
    { key: ROLE_ADMIN_KEY, name: ROLE_ADMIN } as Role,
  ];

  override LoadPermissions(): Observable<Permission> {
    return of({
      roles: this.adminUserRoles,
      licences: [],
      organizationName: 'Mock Organization',
    } as Permission);
  }
}
