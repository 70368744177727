import { Slide } from '../../../core/models/slide.model';

export class CoverTypes {
  public static activeCoverTypes: Slide[] = [
    {
      templateImageUrl: './assets/img/covers/cover01.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover02.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover03.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover04.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover05.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover06.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover07.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover08.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover09.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover10.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover11.jpg',
    } as unknown as Slide,
    {
      templateImageUrl: './assets/img/covers/cover12.jpg',
    } as unknown as Slide,
  ];
}
