import { Component, Input } from '@angular/core';
import { DynamicComponent } from '../../../../../core/models/dynamic-component.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

import { TemplateName } from '../../../../../core/enums/template-name.enum';
import { MediaPlaceholderComponent } from '../../placeholders/media-placeholder/media-placeholder.component';
import { PlaintextPlaceholderComponent } from '../../placeholders/plaintext-placeholder/plaintext-placeholder.component';
import { RichTextPlaceholderComponent } from '../../placeholders/rich-text-placeholder/rich-text-placeholder.component';

@Component({
  selector: 'rh-image-fullscreen-template',
  templateUrl: './image-fullscreen-template.component.html',
  styleUrls: ['./image-fullscreen-template.component.scss'],
  standalone: true,
  imports: [
    MediaPlaceholderComponent,
    PlaintextPlaceholderComponent,
    RichTextPlaceholderComponent,
  ],
})
export class ImageFullscreenTemplateComponent
  extends TemplateBaseComponent
  implements DynamicComponent
{
  public static override templateId = 13;
  public static override templateName =
    TemplateName.IMAGE_FULLSCREEN_TEMPLATE_COMPONENT;
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T13.svg';

  @Input() public urlImage = '';
  @Input() public urlVideo = '';
  @Input() public title = '';
  @Input() public html = '';

  activatedUrlVideo = '';

  private htmlTemp = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL) {
        this.urlImage = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_VIDEO) {
        this.urlVideo = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.HTML) {
        this.html = asset.content;
        this.htmlTemp = asset.content;
      }
    }
  }

  public override onEditModeChanging(): void {
    this.html = this.htmlTemp;
  }

  public override onActivated(): void {
    this.activatedUrlVideo = this.urlVideo;
    console.log('activated', this.constructor.name);
  }

  public onZoomedChanged(val: boolean) {
    this.zoomed = val;
    this.onZoomed.emit(this.zoomed);
  }

  public onUrlImageChange(url: string) {
    this.urlImage = url;
    this.handleAssetChange(this.urlImage, PlaceholderCodes.BlobBased.URL);
  }

  public onUrlVideoChange(url: string) {
    this.activatedUrlVideo = url;
    this.handleAssetChange(
      this.activatedUrlVideo,
      PlaceholderCodes.BlobBased.URL_VIDEO,
    );
  }

  public onTitleChange(title: string): void {
    this.title = title;
    this.handleAssetChange(this.title, PlaceholderCodes.TITLE);
  }

  public onContentChange(content: string): void {
    this.htmlTemp = content;
    this.handleAssetChange(this.htmlTemp, PlaceholderCodes.HTML);
  }
}
