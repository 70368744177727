import { AsyncPipe } from '@angular/common';
import {
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LoadingModule } from '@myreishauer/loading';
import { selectIsLoadingOrSaving } from './shared/store/core/core.selectors';
import { selectPermissions } from './shared/store/permissions/permissions.selectors';

@Component({
  selector: 'rh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, LoadingModule, AsyncPipe, TranslateModule],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }
  subscriptions: Subscription = new Subscription();

  private store = inject(Store);

  permissions$ = this.store.select(selectPermissions);

  screenWidth = 0;
  isLoadingOrSaving$ = this.store.select(selectIsLoadingOrSaving);

  async ngOnInit(): Promise<void> {
    this.onResize();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
