import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { User } from '../../../core/models/user.model';
import { AuthenticateService } from '../../../core/services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import {
  changeLanguage,
  changeLanguageFailure,
  changeLanguageSuccess,
  loadApprovers,
  loadApproversFailure,
  loadApproversSuccess,
  loadEditorsBySubCategory,
  loadEditorsBySubCategoryFailure,
  loadEditorsBySubCategorySuccess,
  loadUser,
  loadUserFailure,
  loadUserSuccess,
  logOutUser,
} from './user.actions';

@Injectable()
export class UserEffects {
  private translate = inject(TranslateService);
  private userService = inject(UserService);
  private actions$ = inject(Actions);
  private authService = inject(AuthenticateService);
  private router = inject(Router);

  loadEditorsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEditorsBySubCategory),
      exhaustMap((action) =>
        this.userService.GetEditors(action.subCategoryName).pipe(
          map((editors) => loadEditorsBySubCategorySuccess({ editors })),
          catchError((error) =>
            observableOf(
              loadEditorsBySubCategoryFailure({ error: error.error }),
            ),
          ),
        ),
      ),
    ),
  );

  loadApproversEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadApprovers),
      exhaustMap(() =>
        this.userService.GetApprovers().pipe(
          map((approvers) => loadApproversSuccess({ approvers })),
          catchError((error) =>
            observableOf(loadApproversFailure(error.error)),
          ),
        ),
      ),
    ),
  );

  loadUserEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUser),
      exhaustMap(() =>
        this.authService.getUser().pipe(
          map((user) =>
            loadUserSuccess({
              user: {
                name: user.name,
                email: user.email,
                ui_locales: user.ui_locales,
                sub: user.sub,
              } as User,
            }),
          ),
          catchError((error) =>
            observableOf(loadUserFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  loadUserSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserSuccess),
        switchMap((action) => this.translate.use(action.user.ui_locales)),
      ),
    { dispatch: false },
  );

  changeLanguageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeLanguage),
      tap((action) => {
        this.translate.use(action.languageKey);
      }),
      switchMap((action) =>
        this.userService
          .ChangeLanguageKey(action.languageKey, action.userId)
          .pipe(
            map(() => changeLanguageSuccess()),
            catchError((error) => observableOf(changeLanguageFailure(error))),
          ),
      ),
    ),
  );

  logoutUserEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logOutUser),
        tap(() => {
          this.authService.logout();
        }),
      ),
    { dispatch: false },
  );
}
