import { MediaAsset } from '../../../core/models/media-asset.model';

export interface MediaState {
  isLoading?: boolean;
  progressUpdate?: number;
  assets: MediaAsset[];
  temporaryAssets: MediaAsset[];
  error?: string;
  editors: string[];
  copySlideSuccessMessage?: string;
}

export const initialState: MediaState = {
  editors: [],
  assets: [],
  temporaryAssets: [],
};
