import { Component, Input } from '@angular/core';
import { DynamicComponent } from '../../../../../core/models/dynamic-component.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

import { TemplateName } from '../../../../../core/enums/template-name.enum';
import { MediaPlaceholderComponent } from '../../placeholders/media-placeholder/media-placeholder.component';
import { PlaintextPlaceholderComponent } from '../../placeholders/plaintext-placeholder/plaintext-placeholder.component';

@Component({
  selector: 'rh-image-full-width-template',
  templateUrl: './image-full-width-template.component.html',
  styleUrls: ['./image-full-width-template.component.scss'],
  standalone: true,
  imports: [PlaintextPlaceholderComponent, MediaPlaceholderComponent],
})
export class ImageFullWidthTemplateComponent
  extends TemplateBaseComponent
  implements DynamicComponent
{
  public static override templateId = 2;
  public static override templateName =
    TemplateName.IMAGE_FULL_WIDTH_TEMPLATE_COMPONENT;
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T2.svg';

  @Input() public urlImage = '';
  @Input() public urlVideo = '';
  @Input() public title = '';
  @Input() public footnote = '';

  public activatedUrlVideo = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL) {
        this.urlImage = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_VIDEO) {
        this.urlVideo = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.FOOT_NOTE) {
        this.footnote = asset.content;
      }
    }
  }

  public override onActivated(): void {
    this.activatedUrlVideo = this.urlVideo;
    console.log('activated', this.constructor.name);
  }

  public onZoomedChanged(val: boolean) {
    this.zoomed = val;
    this.onZoomed.emit(this.zoomed);
  }

  public onTitleChange(title: string) {
    this.title = title;
    this.handleAssetChange(this.title, PlaceholderCodes.TITLE);
  }

  public onUrlImageChange(url: string) {
    this.urlImage = url;
    this.handleAssetChange(this.urlImage, PlaceholderCodes.BlobBased.URL);
  }

  public onUrlVideoChange(url: string) {
    this.activatedUrlVideo = url;
    this.handleAssetChange(
      this.activatedUrlVideo,
      PlaceholderCodes.BlobBased.URL_VIDEO,
    );
  }

  public onFootnoteChange(footnote: string) {
    this.footnote = footnote;
    this.handleAssetChange(this.footnote, PlaceholderCodes.FOOT_NOTE);
  }
}
