import { Type } from '@angular/core';

import { CoverPageTemplateComponent } from '../../features/course-list/components/templates/cover-page-template/cover-page-template.component';
import { FourImagesRightTemplateComponent } from '../../features/course-list/components/templates/four-images-right-template/four-images-right-template.component';
import { FullWidthTextTemplateComponent } from '../../features/course-list/components/templates/full-width-text-template/full-width-text-template.component';
import { GroupHeadingsTemplateComponent } from '../../features/course-list/components/templates/group-headings-template/group-headings-template.component';
import { ImageFullWidthTemplateComponent } from '../../features/course-list/components/templates/image-full-width-template/image-full-width-template.component';
import { ImageFullscreenTemplateComponent } from '../../features/course-list/components/templates/image-fullscreen-template/image-fullscreen-template.component';
import { ImageLeftPlotlyRightTemplateComponent } from '../../features/course-list/components/templates/image-left-plotly-right-template/image-left-plotly-right-template.component';
import { ImageLeftRightTemplateComponent } from '../../features/course-list/components/templates/image-left-right-template/image-left-right-template.component';
import { ImageRightTemplateComponent } from '../../features/course-list/components/templates/image-right-template/image-right-template.component';
import { PlotlyRightTemplateComponent } from '../../features/course-list/components/templates/plotly-right-template/plotly-right-template.component';
import { TextLeftImageRightTemplateComponent } from '../../features/course-list/components/templates/text-left-image-right-template/text-left-image-right-template.component';
import { TitlePageTemplateComponent } from '../../features/course-list/components/templates/title-page-template/title-page-template.component';
import { TwoImagesRightTemplateComponent } from '../../features/course-list/components/templates/two-images-right-template/two-images-right-template.component';
import { TemplateName } from '../enums/template-name.enum';
import { DynamicComponent } from '../models/dynamic-component.interface';
import { Slide } from '../models/slide.model';

export class TemplateTypes {
  public static activeTemplates: Slide[] = [
    {
      templateId: TitlePageTemplateComponent.templateId,
      templateImageUrl: TitlePageTemplateComponent.templateThumbnailUrl,
      template: TitlePageTemplateComponent,
      templateName: TemplateName.TITLE_PAGE_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: GroupHeadingsTemplateComponent.templateId,
      templateImageUrl: GroupHeadingsTemplateComponent.templateThumbnailUrl,
      template: GroupHeadingsTemplateComponent,
      templateName: TemplateName.GROUP_HEADINGS_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: CoverPageTemplateComponent.templateId,
      templateImageUrl: CoverPageTemplateComponent.templateThumbnailUrl,
      template: CoverPageTemplateComponent,
      templateName: TemplateName.COVER_PAGE_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: ImageFullWidthTemplateComponent.templateId,
      templateImageUrl: ImageFullWidthTemplateComponent.templateThumbnailUrl,
      template: ImageFullWidthTemplateComponent,
      templateName: TemplateName.IMAGE_FULL_WIDTH_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: ImageLeftRightTemplateComponent.templateId,
      templateImageUrl: ImageLeftRightTemplateComponent.templateThumbnailUrl,
      template: ImageLeftRightTemplateComponent,
      templateName: TemplateName.IMAGE_LEFT_RIGHT_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: TextLeftImageRightTemplateComponent.templateId,
      templateImageUrl:
        TextLeftImageRightTemplateComponent.templateThumbnailUrl,
      template: TextLeftImageRightTemplateComponent,
      templateName: TemplateName.TEXT_LEFT_IMAGE_RIGHT_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: ImageRightTemplateComponent.templateId,
      templateImageUrl: ImageRightTemplateComponent.templateThumbnailUrl,
      template: ImageRightTemplateComponent,
      templateName: TemplateName.IMAGE_RIGHT_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: FullWidthTextTemplateComponent.templateId,
      templateImageUrl: FullWidthTextTemplateComponent.templateThumbnailUrl,
      template: FullWidthTextTemplateComponent,
      templateName: TemplateName.FULL_WIDTH_TEXT_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: TwoImagesRightTemplateComponent.templateId,
      templateImageUrl: TwoImagesRightTemplateComponent.templateThumbnailUrl,
      template: TwoImagesRightTemplateComponent,
      templateName: TemplateName.TWO_IMAGES_RIGHT_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: FourImagesRightTemplateComponent.templateId,
      templateImageUrl: FourImagesRightTemplateComponent.templateThumbnailUrl,
      template: FourImagesRightTemplateComponent,
      templateName: TemplateName.FOUR_IMAGES_RIGHT_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: ImageLeftPlotlyRightTemplateComponent.templateId,
      templateImageUrl:
        ImageLeftPlotlyRightTemplateComponent.templateThumbnailUrl,
      template: ImageLeftPlotlyRightTemplateComponent,
      templateName: TemplateName.IMAGE_LEFT_PLOTLY_RIGHT_TEMPLATE_COMPONENT,
    } as unknown as Slide,
    {
      templateId: ImageFullscreenTemplateComponent.templateId,
      templateImageUrl: ImageFullscreenTemplateComponent.templateThumbnailUrl,
      template: ImageFullscreenTemplateComponent,
      templateName: TemplateName.IMAGE_FULLSCREEN_TEMPLATE_COMPONENT,
    } as unknown as Slide,
  ];

  public static getDynamicComponentType(
    typeName: TemplateName,
  ): Type<DynamicComponent> {
    return TemplateTypes.getTemplateType(
      typeName,
    ) as unknown as Type<DynamicComponent>;
  }

  public static getTemplateType(
    typeName: TemplateName,
  ): Type<DynamicComponent> {
    let t: Type<DynamicComponent>;
    switch (typeName) {
      case TemplateName.COVER_PAGE_TEMPLATE_COMPONENT:
        t = CoverPageTemplateComponent;
        break;
      case TemplateName.IMAGE_FULL_WIDTH_TEMPLATE_COMPONENT:
        t = ImageFullWidthTemplateComponent;
        break;
      case TemplateName.IMAGE_LEFT_PLOTLY_RIGHT_TEMPLATE_COMPONENT:
        t = ImageLeftPlotlyRightTemplateComponent;
        break;
      case TemplateName.IMAGE_LEFT_RIGHT_TEMPLATE_COMPONENT:
        t = ImageLeftRightTemplateComponent;
        break;
      case TemplateName.IMAGE_RIGHT_TEMPLATE_COMPONENT:
        t = ImageRightTemplateComponent;
        break;
      case TemplateName.PLOTLY_RIGHT_TEMPLATE_COMPONENT:
        t = PlotlyRightTemplateComponent;
        break;
      case TemplateName.FULL_WIDTH_TEXT_TEMPLATE_COMPONENT:
        t = FullWidthTextTemplateComponent;
        break;
      case TemplateName.TEXT_LEFT_IMAGE_RIGHT_TEMPLATE_COMPONENT:
        t = TextLeftImageRightTemplateComponent;
        break;
      case TemplateName.TITLE_PAGE_TEMPLATE_COMPONENT:
        t = TitlePageTemplateComponent;
        break;
      case TemplateName.GROUP_HEADINGS_TEMPLATE_COMPONENT:
        t = GroupHeadingsTemplateComponent;
        break;
      case TemplateName.TWO_IMAGES_RIGHT_TEMPLATE_COMPONENT:
        t = TwoImagesRightTemplateComponent;
        break;
      case TemplateName.FOUR_IMAGES_RIGHT_TEMPLATE_COMPONENT:
        t = FourImagesRightTemplateComponent;
        break;
      case TemplateName.IMAGE_FULLSCREEN_TEMPLATE_COMPONENT:
        t = ImageFullscreenTemplateComponent;
        break;

      default:
        throw new Error(`'${typeName}' is not a valid template name.`);
    }

    return t;
  }

  public static getTemplateThumbnailUrl(typeName: TemplateName): string {
    let url: string;
    switch (typeName) {
      case TemplateName.COVER_PAGE_TEMPLATE_COMPONENT:
        url = CoverPageTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.IMAGE_FULL_WIDTH_TEMPLATE_COMPONENT:
        url = ImageFullWidthTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.IMAGE_LEFT_PLOTLY_RIGHT_TEMPLATE_COMPONENT:
        url = ImageLeftPlotlyRightTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.IMAGE_LEFT_RIGHT_TEMPLATE_COMPONENT:
        url = ImageLeftRightTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.IMAGE_RIGHT_TEMPLATE_COMPONENT:
        url = ImageRightTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.PLOTLY_RIGHT_TEMPLATE_COMPONENT:
        url = PlotlyRightTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.FULL_WIDTH_TEXT_TEMPLATE_COMPONENT:
        url = FullWidthTextTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.TEXT_LEFT_IMAGE_RIGHT_TEMPLATE_COMPONENT:
        url = TextLeftImageRightTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.TITLE_PAGE_TEMPLATE_COMPONENT:
        url = TitlePageTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.GROUP_HEADINGS_TEMPLATE_COMPONENT:
        url = GroupHeadingsTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.TWO_IMAGES_RIGHT_TEMPLATE_COMPONENT:
        url = TwoImagesRightTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.FOUR_IMAGES_RIGHT_TEMPLATE_COMPONENT:
        url = FourImagesRightTemplateComponent.templateThumbnailUrl;
        break;
      case TemplateName.IMAGE_FULLSCREEN_TEMPLATE_COMPONENT:
        url = ImageFullscreenTemplateComponent.templateThumbnailUrl;
        break;
      default:
        throw new Error(`'${typeName}' is not a valid template name.`);
    }

    return url;
  }
}
