import { AsyncPipe, NgClass } from '@angular/common';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadingModule } from '@myreishauer/loading';
import { MediaBlobAssets } from '../../../core/models/media-blob-assets.model';
import { setLoadingActive } from '../../../shared/store/core/core.actions';
import { selectIsLoadingOrSaving } from '../../../shared/store/core/core.selectors';
import { saveTemporaryMediaAsset } from '../../../shared/store/media/media.actions';
import { DragAndDropFilesDirective } from '../../course-list/directives/drag-and-drop-files.directive';

@Component({
  selector: 'rh-media-drag-and-drop-area',
  templateUrl: './media-drag-and-drop-area.component.html',
  styleUrls: ['./media-drag-and-drop-area.component.scss'],
  standalone: true,
  imports: [DragAndDropFilesDirective, NgClass, LoadingModule, AsyncPipe],
})
export class MediaDragAndDropAreaComponent {
  private store = inject(Store);

  @ViewChild('multiFileUpload') public multiFileUpload!: ElementRef;

  public active = false;

  loadingOrSaving$ = this.store.select(selectIsLoadingOrSaving);

  public async onStatusChanged(status: boolean) {
    this.active = status;
  }

  public async onFilesDropped(evt: FileList): Promise<void> {
    this.readMediaFiles(evt);
  }

  public async selectFiles() {
    this.multiFileUpload?.nativeElement?.click();
  }

  public async onFilesSelected(evt: Event): Promise<void> {
    const inputElement = evt.target as HTMLInputElement;
    if (inputElement?.files && inputElement.files.length > 0) {
      this.readMediaFiles(inputElement.files);
    }
  }

  private readMediaFiles(files: FileList): void {
    this.upload(files);
  }

  // Progress bar
  upload(files: FileList) {
    this.store.dispatch(setLoadingActive());

    this.store.dispatch(
      saveTemporaryMediaAsset({
        asset: {
          files: Array.from(files),
        } as MediaBlobAssets,
      }),
    );
  }
}
