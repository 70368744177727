import { Category } from '../../../core/models/category.model';

export interface CategoryState {
  categories?: Category[] | null;
  isLoading?: boolean;
  error?: string;
  sidebarCategory?: string;
}

export const initialState: CategoryState = {};
