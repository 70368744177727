import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  inject,
  Inject,
  ViewChild,
} from '@angular/core';
import { ButtonComponent } from '../../../../core/components/button/button.component';
import { ButtonType } from '../../../../core/components/button/core/button-type.enum';
import { IConfirmationResult } from '../../../../core/components/confirm-cancel-dialog/core/confirmation-result.model';
import { Slide } from '../../../../core/models/slide.model';
import { DialogRef } from '../../../../core/services/dialog/dialog-ref';
import { DIALOG_DATA } from '../../../../core/services/dialog/dialog-tokens';

@Component({
  selector: 'rh-cover-chooser',
  templateUrl: './cover-chooser.component.html',
  styleUrls: ['./cover-chooser.component.scss'],
  standalone: true,
  imports: [ButtonComponent, NgClass],
})
export class CoverChooserComponent {
  private dialogRef = inject(DialogRef);

  public ButtonTypeEnum = ButtonType;
  public currentSelection?: Slide;
  @ViewChild('file') public imageInput!: ElementRef;

  constructor(@Inject(DIALOG_DATA) public covers: Slide[]) {}

  public async close(): Promise<void> {
    this.dialogRef.close({ decision: false } as IConfirmationResult);
  }

  public async select(item: Slide): Promise<void> {
    this.currentSelection = item;

    if (!this.currentSelection) {
      return;
    }

    // Important: We need to remove the reference of the slide-model,
    // otherwise later manipulations would change the same instance.
    this.dialogRef.close({
      decision: true,
      payload: JSON.parse(JSON.stringify(this.currentSelection)) as Slide,
    } as IConfirmationResult);
  }

  public async uploadImage(): Promise<void> {
    if (this.imageInput) {
      this.imageInput.nativeElement.click();
    }
  }

  public async onFileChanged(event: Event): Promise<void> {
    if (!(event.target instanceof HTMLInputElement)) return;
    this.readImage(event.target);
  }

  private readImage(inputValue: HTMLInputElement): void {
    if (!(inputValue.files instanceof FileList)) return;

    const file: File = inputValue.files[0];
    const reader: FileReader = new FileReader();

    reader.onloadend = () => {
      this.dialogRef.close({
        decision: true,
        payload: JSON.parse(
          JSON.stringify({
            templateImageUrl: reader.result as string,
          } as unknown as Slide),
        ),
      } as IConfirmationResult);
    };

    reader.readAsDataURL(file);
  }

  @HostListener('document:keydown', ['$event'])
  public handleKeyPress(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.dialogRef.close();
    }
  }
}
