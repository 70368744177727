<div>
  @if (editMode) {
    <ngx-suneditor
      #editor
      [content]="html"
      [options]="editorOptions"
      (onKeyUp)="onKeyPress()"
      (onChange)="onChange($event)"
      spellcheck="false"
    ></ngx-suneditor>
  } @else {
    <div
      [style]="defaultTextStyle"
      class="viewer sun-editor-editable-customizations"
      [innerHtml]="html | safeHtml"
    ></div>
  }
</div>
