import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { NgClass } from '@angular/common';
import { Store } from '@ngrx/store';
import * as PlotlyJS from 'plotly.js-dist-min';
import { Subscription } from 'rxjs';
import { LoadingModule } from '@myreishauer/loading';
import { BlobAsset } from '../../../../../core/models/blob-asset.model';
import { SafeUrlPipe } from '../../../../../core/pipes/safe-url.pipe';
import { saveTemporarySlideAsset } from '../../../../../shared/store/slide/slide.actions';
import {
  selectSlideProgressUpdate,
  selectSlideUrlUpdate,
} from '../../../../../shared/store/slide/slide.selectors';
import { PlaceholderToolbarComponent } from '../core/toolbar/placeholder-toolbar.component';
import { UrlBasedPlaceholderBaseComponent } from '../core/url-based-placeholder-base.component';

@Component({
  selector: 'rh-iframe-plotly-placeholder',
  templateUrl: './iframe-plotly-placeholder.component.html',
  styleUrls: [
    './iframe-plotly-placeholder.component.scss',
    '../core/placeholder-base.component.scss',
  ],
  standalone: true,
  imports: [NgClass, LoadingModule, PlaceholderToolbarComponent, SafeUrlPipe],
})
export class IframePlotlyPlaceholderComponent
  extends UrlBasedPlaceholderBaseComponent
  implements OnInit
{
  private store = inject(Store);
  private renderer = inject(Renderer2);
  private cd = inject(ChangeDetectorRef);

  @ViewChild('plotlyFrame', { read: ElementRef, static: true })
  public plotlyFrameElementRef!: ElementRef;

  @ViewChild('file') public mediaFileInput!: ElementRef;

  @Input() public placeholderCode = ''; // Use this property to distinguish betweeen multiple media-placeholders on the same template

  public progress?: number;

  private currentProgress$ = this.store.select(selectSlideProgressUpdate);
  private currentUrlUpdate$ = this.store.select(selectSlideUrlUpdate);
  private subscriptions: Subscription = new Subscription();

  private _src = '';
  @Input()
  public get src(): string {
    return this._src;
  }

  public set src(val: string) {
    this._src = val || '';
    this.loading = this._src.length > 0;
  }

  public loading = false;

  private layout: Partial<PlotlyJS.Layout> = { autosize: true };
  private plotlyDiv!: HTMLElement;

  public get enableDelete(): boolean {
    return !!this.src;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.zoomed) {
      this.zoomed = false;
      event.stopImmediatePropagation();
      return false;
    }

    return true;
  }

  constructor() {
    super();
  }

  public ngOnInit(): void {
    if (!this.plotlyFrameElementRef) {
      return;
    }

    this.renderer.listen(
      this.plotlyFrameElementRef.nativeElement,
      'load',
      () => {
        console.log(`iFrame load-event. src is '${this.src}'`);
        if (!this.src) {
          return;
        }

        console.log(`this.plotlyFrameElementRef`, this.plotlyFrameElementRef);

        const body =
          this.plotlyFrameElementRef.nativeElement.contentWindow.document.getElementsByTagName(
            'body',
          )[0];

        console.log(`body '${body}'`);

        // Manipulating iframe content is only possible due to 'same origin'. This saves our life!
        body.style.padding = '0';
        body.style.margin = '0'; // They have some margin for wathever reason

        // Note: If they (who create the plotly output) ever change their output-structure
        // then this whole workaround will fail. E.g. the div-class name must always be the
        // same. And the Plotly Javascript-Object must alway be called 'Plotly' and the
        // Plotly container 'div' must always have an 'id' attribute and each of the loaded
        // objects need to have a unique one (pure html rules) and there must not be multipe
        // plotly-DIVs.
        const plotlyDivClassName = 'plotly-graph-div';

        this.plotlyDiv =
          this.plotlyFrameElementRef.nativeElement.contentWindow.document.getElementsByClassName(
            plotlyDivClassName,
          )[0];

        console.log(`this.plotlyDiv '${this.plotlyDiv}'`);

        // Important: Override the layout configuration in order to allow autosizing!
        PlotlyJS.relayout(this.plotlyDiv, this.layout);

        this.plotlyDiv.style.width = '100%';
        this.plotlyDiv.style.height = '100%';

        this.plotlyFrameElementRef.nativeElement.style.width = '100%';
        this.plotlyFrameElementRef.nativeElement.style.height = '100%';

        // Important: This is just for Chromium-based browsers:
        // We need to set width/height of the first main svg in the plotly
        // chart in order to achieve resizing when using the zooming function.
        const svgElements = this.plotlyDiv.getElementsByTagName('svg');
        if (svgElements) {
          svgElements[0].style.width = '100%';
          svgElements[0].style.height = '100%';
        }

        this.handleLoading();
      },
    );

    this.subscriptions.add(
      this.currentProgress$?.subscribe((update) => {
        if (!update || update.placeholderCode !== this.placeholderCode) {
          return;
        }

        if (update.progress !== undefined) {
          this.progress = update.progress;
        }
      }),
    );

    this.subscriptions.add(
      this.currentUrlUpdate$?.subscribe((update) => {
        if (!update || update.placeholderCode !== this.placeholderCode) {
          return;
        }

        if (!!update.urls[0] && this.url !== update.urls[0]) {
          this.onUrlChange(update.urls[0]);
        }
      }),
    );
  }

  public onUrlChange(url: string) {
    this.urlChange?.emit(url);
    this.progress = 0; // hide the progress bar
  }

  public override onChangeUrl(): void {
    // this.urlChange?.emit('');
  }

  public selectMedia() {
    if (this.mediaFileInput) {
      this.mediaFileInput.nativeElement.click();
    }
  }

  public async onRemoveUrl(): Promise<void> {
    this.urlChange?.emit('');
  }

  public override onZoomChanged() {
    this.handleLoading();
  }

  public async onFileChanged(
    event: Event,
    // placeholderCode: string
  ): Promise<void> {
    if (!(event.target instanceof HTMLInputElement)) return;
    this.readPlotlyFile(event.target, this.placeholderCode);
  }

  private readPlotlyFile(
    inputValue: HTMLInputElement,
    placeholderCode: string,
  ): void {
    if (!(inputValue.files instanceof FileList)) return;

    const files: File[] = [];
    for (let i = 0; i < inputValue.files.length; i++) {
      files.push(inputValue.files[i]);
    }

    this.upload(files, placeholderCode);
  }

  // Progress bar
  upload(files: File[], placeholderCode: string) {
    this.store.dispatch(
      saveTemporarySlideAsset({
        asset: {
          files: files,
          placeholderCode: placeholderCode,
        } as BlobAsset,
        courseId: 0, // todo
      }),
    );
  }

  //
  private handleLoading() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;
      // All of a sudden the change is not detected automatically anymore, so we need to enforce it!
      this.cd.detectChanges();
    }, 200);
  }
}
