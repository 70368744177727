<div
  class="plotly-placeholder"
  [ngClass]="{
    'edit-mode-background': editMode
  }"
  >
  <div
    class="placeholder-object iframe-plotly-placeholder-container items-center"
    [ngClass]="{
      'placeholder-object-zoomed': zoomed
    }"
    >
    <iframe #plotlyFrame [src]="src | safeUrl"></iframe>
    @if (loading) {
      <div class="loading-indicator">
        <rh-loading></rh-loading>
      </div>
    }

    <rh-placeholder-toolbar
      class="plotly-toolbar"
      [editMode]="editMode"
      [enableDelete]="enableDelete"
      [showZoom]="showZoomButton"
      [(zoomed)]="zoomed"
      (contentAdd)="selectMedia()"
      (contentRemove)="onRemoveUrl()"
      (zoomedChange)="onZoomChanged()"
    ></rh-placeholder-toolbar>
  </div>

  <input
    #file
    class="input"
    type="file"
    multiple
    accept="media/*"
    (change)="onFileChanged($event)"
    />

    @if (progress) {
      <div class="progress">
        <div class="progress-bar" [style.width]="progress + '%'">
          {{ progress }}%
        </div>
      </div>
    }
  </div>
