import { Component, Input } from '@angular/core';
import { TemplateName } from '../../../../../core/enums/template-name.enum';
import { DynamicComponent } from '../../../../../core/models/dynamic-component.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { PlaintextPlaceholderComponent } from '../../placeholders/plaintext-placeholder/plaintext-placeholder.component';
import { RichTextPlaceholderComponent } from '../../placeholders/rich-text-placeholder/rich-text-placeholder.component';
import { TemplateBaseComponent } from '../core/template-base.component';

@Component({
  selector: 'rh-full-width-text-template',
  templateUrl: './full-width-text-template.component.html',
  styleUrls: ['./full-width-text-template.component.scss'],
  standalone: true,
  imports: [PlaintextPlaceholderComponent, RichTextPlaceholderComponent],
})
export class FullWidthTextTemplateComponent
  extends TemplateBaseComponent
  implements DynamicComponent
{
  public static override templateId = 7;
  public static override templateName =
    TemplateName.FULL_WIDTH_TEXT_TEMPLATE_COMPONENT;
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T7.svg';

  @Input() public title = '';
  @Input() public html = '';

  private htmlTemp = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.HTML) {
        this.html = asset.content;
        this.htmlTemp = asset.content;
      }
    }
  }

  public override onActivated(): void {
    console.log('activated', this.constructor.name);
  }

  public override onEditModeChanging(): void {
    this.html = this.htmlTemp;
  }

  public onTitleChange(title: string) {
    this.title = title;
    this.handleAssetChange(this.title, PlaceholderCodes.TITLE);
  }

  public onContentChange(content: string) {
    this.htmlTemp = content;
    this.handleAssetChange(this.htmlTemp, PlaceholderCodes.HTML);
  }
}
