import { createReducer, on } from '@ngrx/store';
import {
  loadLanguages,
  loadLanguagesFailure,
  loadLanguagesSuccess,
} from './language.actions';
import { initialState } from './language.state';

export const languageReducer = createReducer(
  initialState,
  on(loadLanguages, (state) => ({
    ...state,
    isLoading: true,
    error: '',
  })),
  on(loadLanguagesSuccess, (state, { languages }) => ({
    ...state,
    isLoading: false,
    languages,
  })),
  on(loadLanguagesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);
