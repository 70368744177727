import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TemplateName } from '../../../../../core/enums/template-name.enum';
import { Asset } from '../../../../../core/models/asset.model';
import { DynamicComponent } from '../../../../../core/models/dynamic-component.interface';

@Component({
  selector: 'rh-base-template',
  template: '',
})
export abstract class TemplateBaseComponent implements DynamicComponent {
  public editMode = false;
  public zoomed = false;
  public static templateId: number;
  public static templateName: TemplateName;
  public static templateThumbnailUrl: string;
  public abstract onActivated(): void;
  public onZoomed = new EventEmitter<boolean>();

  // Use the following event in derived classes if you need to -> will be fired by the slide-template-container...
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onEditModeChanging(): void {}

  private _assets: Asset[] = [];
  @Input()
  public get assets(): Asset[] {
    return this._assets;
  }
  public set assets(val: Asset[]) {
    this._assets = val;
    this.mapAssets();
  }

  @Output()
  public assetChange: EventEmitter<Asset[]> = new EventEmitter<Asset[]>();

  // Each template has its own definition of assets -> map them in the concrete implementation
  abstract mapAssets(): void;

  public findAsset(placeholderCode: string): Asset {
    return this.assets.filter((a) => a.placeholderCode === placeholderCode)[0];
  }

  public createAsset(placeholderCode: string): Asset {
    return { placeholderCode: placeholderCode } as Asset;
  }

  public ensureAsset(placeholderCode: string) {
    const existingAsset = this.findAsset(placeholderCode);

    if (existingAsset) {
      return existingAsset;
    }

    return this.createAsset(placeholderCode);
  }

  //
  protected handleAssetChange(assetContent: string, placeholderCode: string) {
    if (this.assetChange) {
      const change = this.ensureAsset(placeholderCode);
      change.content = assetContent;
      this.assetChange.emit([change]);
    }
  }
}
