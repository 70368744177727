import { Component, Inject } from '@angular/core';
import { ISnackbarData } from './core/snackbar-data.interface';
import { SnackbarType } from './core/snackbar-type.enum';
import { SNACKBAR_DATA } from './snackbar-tokens';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'rh-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule],
})
export class SnackbarComponent {
  public snackbarTypes = SnackbarType;

  constructor(@Inject(SNACKBAR_DATA) public data: ISnackbarData) {}
}
