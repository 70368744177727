import { createReducer, on } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { Course } from '../../../core/models/course.model';
import { Slide } from '../../../core/models/slide.model';
import {
  addSlide,
  deleteSlide,
  pasteSlide,
  saveSlides,
} from '../slide/slide.actions';
import {
  approveRequestSuccess,
  createNewCourseVersion,
  deleteCourse,
  loadCourse,
  loadCourseHistory,
  loadCourseHistorySuccess,
  loadCourses,
  loadCoursesFailure,
  loadCoursesSuccess,
  loadCourseSuccess,
  publishCourse,
  publishCourseFailure,
  publishCourseSuccess,
  rejectApprovalRequest,
  saveCourse,
  unloadCourse,
  unpublishCourse,
  unpublishCourseFailure,
  unpublishCourseSuccess,
} from './course.actions';
import { initialState } from './course.state';

export const courseReducer = createReducer(
  initialState,
  on(loadCourseSuccess, (state, { course }) => ({
    ...state,
    currentCourse: {
      ...course,
      slides: course.slides.map((s) => transformSlideThumbnail(s)),
    },
    isLoading: false,
  })),
  on(unloadCourse, (state) => ({
    ...state,
    currentCourse: null,
    courseHistory: undefined,
    saveSuccess: undefined,
  })),
  on(loadCourses, (state) => ({
    ...state,
    courses: undefined,
    isLoading: true,
  })),
  on(loadCoursesSuccess, (state, { courses }) => ({
    ...state,
    courses: courses.map((c) => transformImageUrl(c)),
    isLoading: false,
  })),
  on(loadCoursesFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(loadCourseHistory, (state) => ({
    ...state,
    courseHistory: undefined,
  })),
  on(loadCourseHistorySuccess, (state, { courseVersions }) => ({
    ...state,
    courseHistory: courseVersions,
    isLoading: false,
  })),
  on(rejectApprovalRequest, (state) => {
    const newState = {
      ...state,
      hasChanged: false,
    };

    if (newState.currentCourse) {
      newState.currentCourse.approvalRequested = '';
      newState.currentCourse.rejected = new Date().toISOString();
    }

    return newState;
  }),
  on(approveRequestSuccess, (state) => {
    const newState = {
      ...state,
      hasChanged: false,
    };

    if (newState.currentCourse) {
      newState.currentCourse.approved = new Date().toISOString();
    }

    return newState;
  }),
  on(publishCourse, (state) => ({
    ...state,
  })),
  on(publishCourseSuccess, (state) => {
    const newState = {
      ...state,
      hasChanged: false,
    };

    if (newState.currentCourse) {
      newState.currentCourse.published = new Date().toISOString();
    }

    return newState;
  }),
  on(publishCourseFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(unpublishCourse, (state) => ({
    ...state,
  })),
  on(unpublishCourseSuccess, (state) => ({
    ...state,
    hasChanged: false,
  })),
  on(unpublishCourseFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(
    loadCourse,
    createNewCourseVersion,
    saveCourse,
    deleteCourse,
    deleteSlide,
    saveSlides,
    addSlide,
    pasteSlide,
    (state) => ({
      ...state,
      currentCourse: null,
    }),
  ),
);

function transformSlideThumbnail(slide: Slide): Slide {
  const assets = slide.assets.map((a) => {
    const isNotBlobAsset =
      !a.placeholderCode.startsWith('URL') ||
      a.content.indexOf('assets/img') !== -1;
    if (isNotBlobAsset) {
      return a;
    }

    return {
      ...a,
    };
  });

  if (!slide.thumbnail) {
    return {
      ...slide,
    };
  }
  const thumbnail =
    slide.thumbnail?.indexOf('assets/img') === -1
      ? `${environment.backendApiUrl}/BlobAssets/${slide.thumbnail}`
      : slide.thumbnail;

  return {
    ...slide,
    displayThumbnail: thumbnail,
    assets: assets,
  };
}

function transformImageUrl(course: Course): Course {
  if (!course.imageUrl) {
    return course;
  }

  const isNotPredefinedAsset = course.imageUrl.indexOf('assets/img') === -1;
  const isBase64Asset = course.imageUrl.startsWith('data:image');

  const imageUrl =
    isNotPredefinedAsset && !isBase64Asset
      ? `${environment.backendApiUrl}/BlobAssets/${course.imageUrl}`
      : course.imageUrl;

  return {
    ...course,
    imageUrl: imageUrl,
  };
}
