import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { LanguageService } from '../../services/language/language.service';
import {
  loadLanguages,
  loadLanguagesFailure,
  loadLanguagesSuccess,
} from './language.actions';

@Injectable()
export class LanguageEffects {
  private languageService = inject(LanguageService);
  private actions$ = inject(Actions);

  getLanguagesAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadLanguages),
      exhaustMap(() =>
        this.languageService.GetLanguages().pipe(
          map((items) => {
            return loadLanguagesSuccess({ languages: items });
          }),
          catchError((error) =>
            observableOf(loadLanguagesFailure({ error: error.error })),
          ),
        ),
      ),
    );
  });
}
