import { createAction, props } from '@ngrx/store';
import { MediaAsset } from '../../../core/models/media-asset.model';
import { MediaBlobAssets } from '../../../core/models/media-blob-assets.model';

export const saveTemporaryMediaAssetsUpdate = createAction(
  '[Media] Save temporary Media Asset Url Update',
  props<{ assets: MediaAsset[] }>(),
);

export const saveTemporaryMediaAssetProgress = createAction(
  '[Slide] Save temporary Media Asset Progress',
  props<{ progress: number }>(),
);

export const saveTemporaryMediaAsset = createAction(
  '[Media] Save temporary Media Asset',
  props<{ asset: MediaBlobAssets }>(),
);

export const deleteTemporaryMediaAsset = createAction(
  '[Media] Delete temporary Media Assets',
  props<{ mediaAssetId: number }>(),
);

export const deleteTemporaryMediaAssetSuccess = createAction(
  '[Media] Delete temporary Media Assets Success',
);

export const deleteTemporaryMediaAssetFailure = createAction(
  '[Media] Delete temporary Media Assets Failure',
  props<{ error: string }>(),
);

export const saveMediaAssets = createAction(
  '[Media] Save Media Assets',
  props<{ assets: MediaAsset[] }>(),
);

export const saveMediaAssetsSuccess = createAction(
  '[Media] Save Media Assets Success',
);

export const saveMediaAssetsFailure = createAction(
  '[Media] Save Media Assets Failure',
  props<{ error: string }>(),
);

export const copySlide = createAction(
  '[Media] Copy Slide',
  props<{ slide: MediaAsset }>(),
);

export const copySlideSuccess = createAction('[Media] Copy Slide Success');

export const copySlideFailure = createAction(
  '[Media] Copy Slide Failure',
  props<{ error: string }>(),
);

export const deleteMediaAsset = createAction(
  '[Media] Delete Media Asset',
  props<{ id: number }>(),
);

export const deleteMediaAssetSuccess = createAction(
  '[Media] Delete Media Asset Success',
);

export const deleteMediaAssetFailure = createAction(
  '[Media] Delete Media Asset Failure',
  props<{ error: string }>(),
);

export const loadMediaAssets = createAction(
  '[Media] Load Media Assets',
  props<{
    mediaType: string;
    searchText: string;
    categoryId: number;
    timeFrame: number;
    editorId: string;
    privateOnly: boolean;
    sortBy: string;
    sortDir: string;
  }>(),
);

export const loadMediaAssetsSuccess = createAction(
  '[Media] Load Media Assets Success',
  props<{ assets: MediaAsset[] }>(),
);

export const loadMediaAssetsFailure = createAction(
  '[Media] Load Media Assets Failure',
  props<{ error: string }>(),
);

export const loadMediaEditors = createAction('[Media] Load Editors');

export const loadMediaEditorsSuccess = createAction(
  '[Media] Load Editors Success',
  props<{ editors: string[] }>(),
);

export const loadMediaEditorsFailure = createAction(
  '[Media] Load Editors Failure',
  props<{ error: string }>(),
);

export const clearTemporaryMediaAssetsUrlUpdate = createAction(
  '[Media] Clear temporary Media Asset Url Update',
);

export const clearMediaMessages = createAction('[Media] Clear Media Messages');
