<div
  class="image-placeholder"
  [ngClass]="{
    'edit-mode-background': editMode,
  }"
>
  @if (isValidMediaAsset && _displayUrl) {
    @switch (overlayMode) {
      @case (OverlayMode.None) {
        <div
          class="placeholder-object img-container items-center"
          [ngClass]="zoomed ? 'placeholder-object-zoomed' : ''"
          [style.background-size]="objectFit"
          [style.background-image]="'url(' + _displayUrl + ')'"
        ></div>
      }

      @case (OverlayMode.Partial) {
        <div
          class="placeholder-object img-container items-center"
          [ngClass]="zoomed ? 'placeholder-object-zoomed' : ''"
          [style.background-size]="objectFit"
          [style.background-image]="
            'linear-gradient(to right bottom, rgba(64,92,154, 1), rgba(64,92,154, .65) 40%, rgba(64,92,154, .0) 70%, rgba(64,92,154, 0.0) 100%), url(' +
            _displayUrl +
            ')'
          "
        ></div>
      }

      @case (OverlayMode.Full) {
        <div
          class="placeholder-object img-container items-center"
          [ngClass]="zoomed ? 'placeholder-object-zoomed' : ''"
          [style.background-size]="objectFit"
          [style.background-image]="
            'linear-gradient(0deg, rgba(40,68,129, 0.9), rgba(40,68,129, 0.9)), url(' +
            _displayUrl +
            ')'
          "
        ></div>
      }
    }
  }

  <rh-placeholder-toolbar
    *ngIf="showToolbar"
    class="image-toolbar"
    [editMode]="editMode"
    [enableDelete]="enableDelete"
    [showZoom]="showZoom"
    [(zoomed)]="zoomed"
    (contentRemove)="onChangeUrl()"
    (contentAdd)="selectImage()"
    (zoomedChange)="onZoomChanged($event)"
  ></rh-placeholder-toolbar>
</div>
