import { User } from '../../../core/models/user.model';

export interface UserState {
  isLoading?: boolean;
  error?: string;
  language: string;
  approvers?: string[];
  editors: string[] | null;
  user?: User;
}

export const initialState: UserState = {
  language: 'en',
  editors: null,
};
