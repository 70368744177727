import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermissionsState } from './permissions.state';

export const selectPermissionsState =
  createFeatureSelector<PermissionsState>('permissions');

export const selectPermissions = createSelector(
  selectPermissionsState,
  (state) => state.permissions,
);

export const selectPermissionsError = createSelector(
  selectPermissionsState,
  (state) => state.error,
);
