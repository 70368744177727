import { Component, Input } from '@angular/core';
import { DynamicComponent } from '../../../../../core/models/dynamic-component.interface';
import { OverlayMode } from '../../../enums/overlay-mode.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

import { TemplateName } from '../../../../../core/enums/template-name.enum';
import { ImagePlaceholderComponent } from '../../placeholders/image-placeholder/image-placeholder.component';
import { PlaintextPlaceholderComponent } from '../../placeholders/plaintext-placeholder/plaintext-placeholder.component';

@Component({
  selector: 'rh-title-page-template',
  templateUrl: './title-page-template.component.html',
  styleUrls: ['./title-page-template.component.scss'],
  standalone: true,
  imports: [PlaintextPlaceholderComponent, ImagePlaceholderComponent],
})
export class TitlePageTemplateComponent
  extends TemplateBaseComponent
  implements DynamicComponent
{
  public overlayMode: OverlayMode = OverlayMode.Partial;

  public static override templateId = 9;
  public static override templateName =
    TemplateName.TITLE_PAGE_TEMPLATE_COMPONENT;
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T9.svg';

  private defaultBackgroundUrl =
    './assets/img/template-backgrounds/Reishauer_Haupttitel.jpg';
  private _url: string = this.defaultBackgroundUrl;
  @Input() public get url(): string {
    return this._url;
  }
  public set url(val: string) {
    if (!val) {
      this._url = this.defaultBackgroundUrl;
    } else {
      this._url = val;
    }
  }

  @Input() public title = '';
  @Input() public subTitle = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.SUB_TITLE) {
        this.subTitle = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL) {
        this.url = asset.content;
      }
    }
  }

  public override onActivated(): void {
    console.log('activated', this.constructor.name);
  }

  public onZoomedChanged(val: boolean) {
    this.zoomed = val;
    this.onZoomed.emit(this.zoomed);
  }

  public onTitleChange(title: string) {
    this.title = title;
    this.handleAssetChange(this.title, PlaceholderCodes.TITLE);
  }

  public onSubTitleChange(subTitle: string) {
    this.subTitle = subTitle;
    this.handleAssetChange(this.subTitle, PlaceholderCodes.SUB_TITLE);
  }

  public onUrlChange(url: string) {
    this.url = url;
    this.handleAssetChange(this.url, PlaceholderCodes.BlobBased.URL);
  }
}
