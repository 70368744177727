import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { PermissionsService } from '../../../core/services/permissions/permissions.service';
import {
  loadPermissions,
  loadPermissionsFailure,
  loadPermissionsSuccess,
} from './permissions.actions';

@Injectable()
export class PermissionsEffects {
  private permissionsService = inject(PermissionsService);
  private actions$ = inject(Actions);

  loadPermissionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPermissions),
      exhaustMap(() =>
        this.permissionsService.LoadPermissions().pipe(
          tap((x) => {
            console.log('loaded permissions:', x);
          }),
          map((permissions) =>
            loadPermissionsSuccess({
              permissions: permissions,
            }),
          ),
          catchError((error) =>
            observableOf(loadPermissionsFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );
}
