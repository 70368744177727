import { createAction, props } from '@ngrx/store';
import { BlobAsset } from '../../../core/models/blob-asset.model';
import { Slide } from '../../../core/models/slide.model';

export const addSlide = createAction(
  '[Slide] Add Slide',
  props<{ slide: Slide; courseId: number; versionNumber: number }>(),
);

export const addSlideSuccess = createAction(
  '[Slide] Add Slide Success',
  props<{ courseId: number; versionNumber: number }>(),
);

export const addSlideFailure = createAction(
  '[Slide] Add Slide Failure',
  props<{ error: string; courseId: number; versionNumber: number }>(),
);

export const saveSlides = createAction(
  '[Slide] Save Slides',
  props<{
    courseId: number;
    versionNumber: number;
    newOrChangedSlides: Slide[];
    thumbnail?: string;
    thumbnailSlideId?: number;
  }>(),
);

export const saveSlidesSuccess = createAction(
  '[Slide] Save Slides Success',
  props<{
    courseId: number;
    versionNumber: number;
  }>(),
);

export const saveSlidesFailure = createAction(
  '[Slide] Save Slides Failure',
  props<{ error: string }>(),
);

export const saveTemporarySlideAsset = createAction(
  '[Slide] Save temporary Slide Asset',
  props<{ asset: BlobAsset; courseId: number }>(),
);

export const saveTemporarySlideAssetProgress = createAction(
  '[Slide] Save temporary Slide Asset Progress',
  props<{ progress: number; placeholderCode: string }>(),
);

export const saveTemporarySlideAssetUrlUpdate = createAction(
  '[Slide] Save temporary Slide Asset Url Update',
  props<{ urls: string[]; placeholderCode: string }>(),
);

export const saveTemporarySlideAssetsFailure = createAction(
  '[Slide] Save temporary Slide Assets Failure',
  props<{ error: string }>(),
);

export const deleteSlide = createAction(
  '[Slide] Delete Slide',
  props<{ slide: number; courseId: number; versionNumber: number }>(),
);

export const deleteSlideSuccess = createAction(
  '[Slide] Delete Slide Success',
  props<{ courseId: number; versionNumber: number }>(),
);

export const deleteSlideFailure = createAction(
  '[Slide] Delete Slide Failure',
  props<{ error: string }>(),
);

export const pasteSlide = createAction(
  '[Slide] Paste Slide',
  props<{
    slideId: number;
    courseId: number;
    courseVersionId: number;
    versionNumber: number;
    position: number;
  }>(),
);

export const pasteSlideSuccess = createAction(
  '[Slide] Paste Slide Success',
  props<{ courseId: number; versionNumber: number }>(),
);

export const pasteSlideFailure = createAction(
  '[Slide] Paste Slide Failure',
  props<{ error: string }>(),
);

export const slideFocusChanged = createAction(
  '[Slide] Slide Focus Changed',
  props<{ slideFocused: boolean }>(),
);

export const resetSlideState = createAction('[Slide] Reset Slide State');
