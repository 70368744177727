import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from '../../../../../../core/services/dialog/dialog.service';
import { IconComponent } from '../../../../../../core/components/icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'rh-placeholder-toolbar',
    templateUrl: './placeholder-toolbar.component.html',
    styleUrls: [
        './placeholder-toolbar.component.scss',
        '../placeholder-base.component.scss',
    ],
    standalone: true,
    imports: [
    NgClass,
    IconComponent
],
})
export class PlaceholderToolbarComponent {
  @Input() public zoomed = false;

  @Input() public enableDelete = false;
  @Input() public showZoom = false;

  @Input()
  public editMode = false;

  @Output() public zoomedChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public contentRemove: EventEmitter<void> = new EventEmitter<void>();
  @Output() public contentAdd: EventEmitter<void> = new EventEmitter<void>();

  constructor(public dialog: DialogService) {}

  public async toggleZoom() {
    this.zoomed = !this.zoomed;
    this.zoomedChange?.emit(this.zoomed);
  }

  public async remove() {
    if (!this.enableDelete) {
      return;
    }

    this.contentRemove?.emit();
  }

  public async add() {
    this.contentAdd?.emit();
  }
}
