<div class="title-page-template-container">
  <div class="content-container">
    <div class="content-frame">
      <div class="title-container">
        <rh-plaintext-placeholder
          class="main-title"
          [fontSize]="'var(--font-size-title)'"
          [lineHeight]='"1em"'
          [fontColor]="'#FFFFFF'"
          [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
          [text]="title"
          [editMode]="editMode"
          (textChange)="onTitleChange($event)"
        ></rh-plaintext-placeholder>
        <rh-plaintext-placeholder
          class="sub-title"
          [fontSize]="'var(--font-size-sub-title)'"
          fontFamily="Helvetica Neue Light"
          [lineHeight]='"1em"'
          [fontColor]="'#FFFFFF'"
          [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
          [text]="subTitle"
          [editMode]="editMode"
          [uppercase]="false"
          (textChange)="onSubTitleChange($event)"
        ></rh-plaintext-placeholder>
      </div>
      <rh-image-placeholder
        class="image-placeholder"
        [showToolbar]="true"
        [showZoom]="false"
        [url]="url"
        [objectFit]="'cover'"
        [editMode]="editMode"
        (zoomedChange)="onZoomedChanged($event)"
        (urlChange)="onUrlChange($event)"
        [overlayMode]="overlayMode"
      ></rh-image-placeholder>
    </div>
  </div>
  @if (zoomed) {
    <div class="zoom-backdrop"></div>
  }
</div>
