import { Component, Input } from '@angular/core';
import { DynamicComponent } from '../../../../../core/models/dynamic-component.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

import { TemplateName } from '../../../../../core/enums/template-name.enum';
import { MediaPlaceholderComponent } from '../../placeholders/media-placeholder/media-placeholder.component';
import { PlaintextPlaceholderComponent } from '../../placeholders/plaintext-placeholder/plaintext-placeholder.component';
import { RichTextPlaceholderComponent } from '../../placeholders/rich-text-placeholder/rich-text-placeholder.component';

@Component({
  selector: 'rh-two-images-right-template',
  templateUrl: './two-images-right-template.component.html',
  styleUrls: ['./two-images-right-template.component.scss'],
  standalone: true,
  imports: [
    PlaintextPlaceholderComponent,
    RichTextPlaceholderComponent,
    MediaPlaceholderComponent,
  ],
})
export class TwoImagesRightTemplateComponent
  extends TemplateBaseComponent
  implements DynamicComponent
{
  public static override templateId = 11;
  public static override templateName =
    TemplateName.TWO_IMAGES_RIGHT_TEMPLATE_COMPONENT;
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T11.svg';

  @Input() public title = '';
  @Input() public html = '';
  @Input() public urlImageTop = '';
  @Input() public urlImageBottom = '';
  @Input() public urlVideoTop = '';
  @Input() public urlVideoBottom = '';
  @Input() public footnoteTop = '';
  @Input() public footnoteBottom = '';

  public activatedUrlVideoTop = '';
  public activatedUrlVideoBottom = '';

  private htmlTemp = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_TOP) {
        this.urlImageTop = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_VIDEO_TOP) {
        this.urlVideoTop = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_BOTTOM) {
        this.urlImageBottom = asset.content;
      }
      if (
        asset.placeholderCode === PlaceholderCodes.BlobBased.URL_VIDEO_BOTTOM
      ) {
        this.urlVideoBottom = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.HTML) {
        this.html = asset.content;
        this.htmlTemp = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.FOOT_NOTE_TOP) {
        this.footnoteTop = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.FOOT_NOTE_BOTTOM) {
        this.footnoteBottom = asset.content;
      }
    }
  }

  public override onActivated(): void {
    this.activatedUrlVideoTop = this.urlVideoTop;
    this.activatedUrlVideoBottom = this.urlVideoBottom;
    console.log('activated', this.constructor.name);
  }

  public onZoomedChanged(val: boolean) {
    this.zoomed = val;
    this.onZoomed.emit(this.zoomed);
  }

  public override onEditModeChanging(): void {
    this.html = this.htmlTemp;
  }

  public onTitleChange(title: string) {
    this.title = title;
    this.handleAssetChange(this.title, PlaceholderCodes.TITLE);
  }

  public onContentChange(content: string) {
    this.htmlTemp = content;
    this.handleAssetChange(this.htmlTemp, PlaceholderCodes.HTML);
  }

  public onUrlImageTopChange(url: string) {
    this.urlImageTop = url;
    this.handleAssetChange(
      this.urlImageTop,
      PlaceholderCodes.BlobBased.URL_TOP,
    );
  }

  public onUrlVideoTopChange(url: string) {
    this.activatedUrlVideoTop = url;
    this.handleAssetChange(
      this.activatedUrlVideoTop,
      PlaceholderCodes.BlobBased.URL_VIDEO_TOP,
    );
  }

  public onUrlImageBottomChange(url: string) {
    this.urlImageBottom = url;
    this.handleAssetChange(
      this.urlImageBottom,
      PlaceholderCodes.BlobBased.URL_BOTTOM,
    );
  }

  public onUrlVideoBottomChange(url: string) {
    this.activatedUrlVideoBottom = url;
    this.handleAssetChange(
      this.activatedUrlVideoBottom,
      PlaceholderCodes.BlobBased.URL_VIDEO_BOTTOM,
    );
  }

  public onFootnoteTopChange(footnote: string) {
    this.footnoteTop = footnote;
    this.handleAssetChange(this.footnoteTop, PlaceholderCodes.FOOT_NOTE_TOP);
  }

  public onFootnoteBottomChange(footnote: string) {
    this.footnoteBottom = footnote;
    this.handleAssetChange(
      this.footnoteBottom,
      PlaceholderCodes.FOOT_NOTE_BOTTOM,
    );
  }
}
