import { createReducer, on } from '@ngrx/store';
import {
  changeLanguage,
  changeLanguageFailure,
  loadApprovers,
  loadApproversFailure,
  loadApproversSuccess,
  loadEditorsBySubCategory,
  loadEditorsBySubCategoryFailure,
  loadEditorsBySubCategorySuccess,
  loadUserSuccess,
} from './user.actions';
import { initialState } from './user.state';

export const userReducer = createReducer(
  initialState,
  on(loadApprovers, (state) => ({
    ...state,
    isLoading: true,
    approvers: [],
    error: '',
  })),
  on(loadApproversSuccess, (state, { approvers }) => ({
    ...state,
    approvers,
    isLoading: false,
  })),
  on(loadApproversFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),
  on(loadEditorsBySubCategory, (state) => ({
    ...state,
    editors: null,
  })),
  on(loadEditorsBySubCategorySuccess, (state, { editors }) => ({
    ...state,
    editors,
  })),
  on(loadEditorsBySubCategoryFailure, (state, { error }) => ({
    ...state,
    error,
    editors: [],
  })),
  on(changeLanguage, (state, { languageKey }) => ({
    ...state,
    language: languageKey,
  })),
  on(changeLanguageFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(loadUserSuccess, (state, { user }) => ({
    ...state,
    user,
    language: user.ui_locales,
  })),
);
