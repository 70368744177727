import { createAction, props } from '@ngrx/store';
import { User } from '../../../core/models/user.model';

export const loadApprovers = createAction('[User] Load Approvers');

export const loadApproversSuccess = createAction(
  '[User] Load Approvers Success',
  props<{ approvers: string[] }>(),
);

export const loadApproversFailure = createAction(
  '[User] Load Approvers Failure',
  props<{ error: string }>(),
);

export const loadEditorsBySubCategory = createAction(
  '[User] Load Editors',
  props<{ subCategoryName: string }>(),
);

export const loadEditorsBySubCategorySuccess = createAction(
  '[User] Load Editors Success',
  props<{ editors: string[] }>(),
);

export const loadEditorsBySubCategoryFailure = createAction(
  '[User] Load Editors Failure',
  props<{ error: string }>(),
);

export const loadUser = createAction('[User] Load User');

export const loadUserSuccess = createAction(
  '[User] Load User Success',
  props<{ user: User }>(),
);

export const loadUserFailure = createAction(
  '[User] Load User Failure',
  props<{ error: string }>(),
);

export const changeLanguage = createAction(
  '[User] Change Language',
  props<{ languageKey: string; userId: string }>(),
);

export const changeLanguageSuccess = createAction(
  '[User] Change Language Success',
);

export const changeLanguageFailure = createAction(
  '[User] Change Language Failure',
  props<{ error: string }>(),
);

export const logOutUser = createAction('[Auth] Logout User');