<div class="image-full-width-template-container top-bottom-padding-template">
  <div class="content-container">
    <rh-plaintext-placeholder
      class="main-title"
      [fontSize]="'var(--font-size-title-smaller)'"
      [lineHeight]="'1em'"
      [fontColor]="'#254FAA'"
      [text]="title"
      [editMode]="editMode"
      (textChange)="onTitleChange($event)"
    ></rh-plaintext-placeholder>
    <div class="image-container">
      <rh-media-placeholder
        class="image-placeholder"
        [urlImage]="urlImage"
        [urlVideo]="activatedUrlVideo"
        [editMode]="editMode"
        (zoomedChange)="onZoomedChanged($event)"
        (urlImageChange)="onUrlImageChange($event)"
        (urlVideoChange)="onUrlVideoChange($event)"
      ></rh-media-placeholder>
      <rh-plaintext-placeholder
        class="footnote"
        [fontSize]="'var(--font-size-small-text)'"
        [lineHeight]="'1em'"
        [fontColor]="'#4f4d4d'"
        [text]="footnote"
        [uppercase]="false"
        [editMode]="editMode"
        (textChange)="onFootnoteChange($event)"
      ></rh-plaintext-placeholder>
    </div>
  </div>
  @if (zoomed) {
    <div class="zoom-backdrop"></div>
  }
</div>
