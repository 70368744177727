import { HttpEventType } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { MediaAsset } from '../../../core/models/media-asset.model';
import { SnackbarType } from '../../../core/services/snackbar/core/snackbar-type.enum';
import { SnackbarComponent } from '../../../core/services/snackbar/snackbar.component';
import { SnackbarService } from '../../../core/services/snackbar/snackbar.service';
import { AssetService } from '../../services/asset/asset.service';
import { setLoadingInactive } from '../core/core.actions';
import {
  copySlide,
  copySlideFailure,
  copySlideSuccess,
  deleteMediaAsset,
  deleteMediaAssetFailure,
  deleteMediaAssetSuccess,
  deleteTemporaryMediaAsset,
  deleteTemporaryMediaAssetFailure,
  deleteTemporaryMediaAssetSuccess,
  loadMediaAssets,
  loadMediaAssetsFailure,
  loadMediaAssetsSuccess,
  loadMediaEditors,
  loadMediaEditorsFailure,
  loadMediaEditorsSuccess,
  saveMediaAssets,
  saveMediaAssetsFailure,
  saveMediaAssetsSuccess,
  saveTemporaryMediaAsset,
  saveTemporaryMediaAssetProgress,
  saveTemporaryMediaAssetsUpdate,
} from './media.actions';

@Injectable()
export class MediaEffects {
  private mediaAssetService = inject(AssetService);
  private actions$ = inject(Actions);
  private snackbar = inject(SnackbarService);

  saveTemporaryMediaAssetEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveTemporaryMediaAsset),
      exhaustMap((action) => {
        return this.mediaAssetService
          .SaveTemporaryMediaAsset(action.asset.files)
          .pipe(
            map((event) => {
              if (event.type == HttpEventType.UploadProgress && event.total) {
                const progress = Math.round((100 / event.total) * event.loaded);
                return saveTemporaryMediaAssetProgress({
                  progress: Math.min(progress, 98), // 100% is received when we get back the url, see below
                });
              }

              // Final event when 100% & Success is reported
              if (event.type == HttpEventType.Response) {
                saveTemporaryMediaAssetProgress({
                  progress: 100,
                });

                return saveTemporaryMediaAssetsUpdate({
                  assets: (event.body as { result: MediaAsset[] })?.result,
                });
              }

              // Fallback/placeholder case, which is not represented in the UI (but we need to return something here...)
              return saveTemporaryMediaAssetProgress({
                progress: 0,
              });
            }),
            catchError((error) =>
              of(saveMediaAssetsFailure({ error: error.error })),
            ),
          );
      }),
    ),
  );

  deleteTemporaryMediaAssetEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTemporaryMediaAsset),
      exhaustMap((action) => {
        return this.mediaAssetService
          .DeleteTemporaryMediaAsset(action.mediaAssetId)
          .pipe(
            map(() => {
              return deleteTemporaryMediaAssetSuccess();
            }),
            catchError((error) =>
              of(
                deleteTemporaryMediaAssetFailure({
                  error: error.error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  loadMediaAssetsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMediaAssets),
      exhaustMap((action) =>
        this.mediaAssetService
          .GetMediaAssets(
            action.mediaType,
            action.searchText,
            action.categoryId,
            action.timeFrame,
            action.editorId,
            action.privateOnly,
            action.sortBy,
            action.sortDir,
          )
          .pipe(
            map((assets) => loadMediaAssetsSuccess({ assets })),
            catchError((error) =>
              of(loadMediaAssetsFailure({ error: error.error })),
            ),
          ),
      ),
    ),
  );

  loadMediaAssetsSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadMediaAssetsSuccess),
        switchMap((action) => action.assets),
      ),
    { dispatch: false },
  );

  saveMediaAssetsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveMediaAssets),
      exhaustMap((action) => {
        return this.mediaAssetService.SaveMediaAssets(action.assets).pipe(
          map(() => {
            return saveMediaAssetsSuccess();
          }),
          catchError((error) =>
            of(saveMediaAssetsFailure({ error: error.error })),
          ),
        );
      }),
    ),
  );

  saveMediaAssetsFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveMediaAssetsFailure),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  copySlideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copySlide),
      exhaustMap((action) => {
        return this.mediaAssetService.SaveMediaAssets([action.slide]).pipe(
          map(() => {
            return copySlideSuccess();
          }),
          catchError((error) => of(copySlideFailure({ error: error.error }))),
        );
      }),
    ),
  );

  copySlideSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copySlideSuccess),
      tap(() => {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Success,
          text: `snackbar.slideCopied`,
        });
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  copySlideFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copySlideFailure),
      tap((action) => {
        console.error(action.error);

        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: 'snackbar.slideCopyFailed',
        });
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  deleteMediaAssetsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMediaAsset),
      exhaustMap((action) => {
        return this.mediaAssetService.DeleteMediaAsset(action.id).pipe(
          map(() => {
            return deleteMediaAssetSuccess();
          }),
          catchError((error) =>
            of(deleteMediaAssetFailure({ error: error.error })),
          ),
        );
      }),
    ),
  );

  loadMediaEditorsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMediaEditors),
      exhaustMap(() =>
        this.mediaAssetService.GetMediaAssetEditors().pipe(
          map((editors) => loadMediaEditorsSuccess({ editors })),
          catchError((error) =>
            of(loadMediaEditorsFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  loadEditorsSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadMediaEditorsSuccess),
        switchMap((action) => action.editors),
      ),
    { dispatch: false },
  );
}
