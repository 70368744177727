import { Component, Input } from '@angular/core';

@Component({
    selector: 'rh-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    standalone: true
})
export class IconComponent {

  @Input() imageUrl!: string;

}
