import { Component, Input } from '@angular/core';
import { DynamicComponent } from '../../../../../core/models/dynamic-component.interface';
import { PlaceholderCodes } from '../../placeholders/core/placeholder-codes';
import { TemplateBaseComponent } from '../core/template-base.component';

import { TemplateName } from '../../../../../core/enums/template-name.enum';
import { IframePlotlyPlaceholderComponent } from '../../placeholders/iframe-plotly-placeholder/iframe-plotly-placeholder.component';
import { MediaPlaceholderComponent } from '../../placeholders/media-placeholder/media-placeholder.component';

@Component({
  selector: 'rh-image-left-plotly-right-template',
  templateUrl: './image-left-plotly-right-template.component.html',
  styleUrls: ['./image-left-plotly-right-template.component.scss'],
  standalone: true,
  imports: [MediaPlaceholderComponent, IframePlotlyPlaceholderComponent],
})
export class ImageLeftPlotlyRightTemplateComponent
  extends TemplateBaseComponent
  implements DynamicComponent
{
  public static override templateId = 3;
  public static override templateName =
    TemplateName.IMAGE_LEFT_PLOTLY_RIGHT_TEMPLATE_COMPONENT;
  public static override templateThumbnailUrl =
    './assets/img/template-thumbnails/T3.svg';

  @Input() public title = '';
  @Input() public urlLeft = '';
  @Input() public urlRight = ''; // Plotly url

  // With this indirection we can delay the loading of the iframe content
  // until this slide is activated (navigated to)
  public activatedPlotlyUrl = '';
  public activatedUrlVideoLeft = '';

  constructor() {
    super();
  }

  override mapAssets(): void {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];

      if (asset.placeholderCode === PlaceholderCodes.TITLE) {
        this.title = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_LEFT) {
        this.urlLeft = asset.content;
      }
      if (asset.placeholderCode === PlaceholderCodes.BlobBased.URL_RIGHT) {
        this.urlRight = asset.content;
      }
    }
  }

  public override onActivated(): void {
    this.activatedPlotlyUrl = this.urlRight;
    console.log('activated', this.constructor.name);
  }

  public onZoomedChanged(val: boolean) {
    this.zoomed = val;
    this.onZoomed.emit(this.zoomed);
  }

  public onImageUrlChange(url: string) {
    this.urlLeft = url;
    this.handleAssetChange(this.urlLeft, PlaceholderCodes.BlobBased.URL_LEFT);
  }

  public onUrlVideoTopLeftChange(url: string) {
    this.activatedUrlVideoLeft = url;

    this.handleAssetChange(
      this.activatedUrlVideoLeft,
      PlaceholderCodes.BlobBased.URL_VIDEO_LEFT,
    );
  }

  public onPlotlyUrlChange(url: string) {
    this.activatedPlotlyUrl = url;
    this.handleAssetChange(
      this.activatedPlotlyUrl,
      PlaceholderCodes.BlobBased.URL_RIGHT,
    );
  }
}
