import { inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { map, Observable } from 'rxjs';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateService {
  private authService = inject(AuthService);

  login(): void {
    this.authService.loginWithRedirect();
  }

  isAuthenticated(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  getUser(): Observable<User> {
    return this.authService.user$.pipe(
      map((user) => {
        return user as User;
      }),
    );
  }

  logout(): void {
    this.authService.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }
}
