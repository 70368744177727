import { createAction, props } from '@ngrx/store';
import { Permission } from '../../../core/models/permission.model';

export const loadPermissions = createAction('[Permissions] Load Permissions');

export const loadPermissionsSuccess = createAction(
  '[Permissions] Load Permissions Success',
  props<{ permissions: Permission }>(),
);

export const loadPermissionsFailure = createAction(
  '[Permissions] Load Permissions Failure',
  props<{ error: string }>(),
);
