import { createAction, props } from '@ngrx/store';
import { Language } from '../../../core/models/language.model';

export const loadLanguages = createAction('[Languages] Load Languages');

export const loadLanguagesSuccess = createAction(
  '[Languages] Load Languages Success',
  props<{ languages: Language[] }>(),
);

export const loadLanguagesFailure = createAction(
  '[Languages] Load Languages Failure',
  props<{ error: string }>(),
);
