import { DatePipe, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../core/components/button/button.component';
import { CheckboxComponent } from '../../../core/components/checkbox/checkbox.component';
import { DropdownComponent } from '../../../core/components/dropdown/dropdown.component';
import { TextboxComponent } from '../../../core/components/textbox/textbox.component';
import { Category } from '../../../core/models/category.model';
import { MediaTile } from '../../../core/models/media-tile.model';

@Component({
  selector: 'rh-media-metadata-viewer',
  templateUrl: './media-metadata-viewer.component.html',
  styleUrls: ['./media-metadata-viewer.component.scss'],
  standalone: true,
  imports: [
    NgStyle,
    TextboxComponent,
    DropdownComponent,
    CheckboxComponent,
    ButtonComponent,
    DatePipe,
    TranslateModule,
  ],
})
export class MediaMetadataViewerComponent {
  @Input()
  public editMode = false;

  @Input()
  public categories?: Category[];

  private _tiles: MediaTile[] = [];
  @Input()
  public get tiles(): MediaTile[] {
    return this._tiles;
  }

  public set tiles(value: MediaTile[]) {
    this._tiles = value;
    this.currentTileIndex = 0;
    if (value.length) {
      this.currentTile = this._tiles[0];
      this.ensureCategory(this._tiles);
    }
  }

  // Form fields
  public currentTile?: MediaTile;
  public currentTileIndex = 0;

  @Output()
  public mediaDeclined: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public confirmationDone: EventEmitter<MediaTile[]> = new EventEmitter<
    MediaTile[]
  >();

  public get isVideo(): boolean {
    return (
      (this.currentTile &&
        this.currentTile.imageUrl &&
        this.currentTile.imageUrl.indexOf('.mp4') > 0) ||
      false
    );
  }

  public get isValid(): boolean {
    return (
      !!this.currentTile &&
      !!this.currentTile.title &&
      this.currentTile.title.length > 0 &&
      this.currentTile.categoryId > 0
    );
  }

  public async onCategoryChanged(category: Category) {
    if (!this.currentTile) {
      return;
    }
    localStorage.setItem(
      'last-category',
      JSON.stringify({ id: category.id, name: category.name }),
    );
    this.currentTile.categoryId = category.id;
  }

  public async dismissAsset(tile: MediaTile) {
    if (!tile.imageUrl) {
      return;
    }

    this.mediaDeclined?.emit(tile.id);

    if (!this.moveNext(false, tile.imageUrl)) {
      return;
    }

    this.confirmationDone?.emit(this.tiles.filter((f) => f.selected));
  }

  public async confirmAsset(url?: string) {
    if (!this.moveNext(true, url)) {
      return;
    }

    this.confirmationDone?.emit(this.tiles.filter((f) => f.selected));
  }

  public async cancel() {
    this.confirmationDone?.emit([]);
  }

  public async save(tile: MediaTile) {
    this.confirmationDone?.emit([tile]);
  }

  //
  private moveNext(select: boolean, url?: string) {
    if (!url || !this.currentTile) {
      return true;
    }

    this.currentTile.selected = select;

    if (this.currentTileIndex < this.tiles.length - 1) {
      this.currentTile = this.tiles[++this.currentTileIndex];
      return false;
    }

    this.currentTile = undefined;
    this.currentTileIndex = 0;

    return true;
  }

  private ensureCategory(mediaTiles: MediaTile[]) {
    // If the tile does not have a category, we will use the last one the user selected by design
    const lastCategory = localStorage.getItem('last-category');
    if (!lastCategory) {
      return;
    }

    const parsedCategory = JSON.parse(lastCategory) as Category;

    for (let i = 0; i < mediaTiles.length; i++) {
      const mediaTile = mediaTiles[i];

      if (!mediaTile.category) {
        mediaTile.category = parsedCategory;
        mediaTile.categoryId = mediaTile.category.id;
        mediaTile.categoryName = mediaTile.category.name;
      }
    }
  }
}
