import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IConfirmationResult } from '../../../../../core/components/confirm-cancel-dialog/core/confirmation-result.model';
import { DialogService } from '../../../../../core/services/dialog/dialog.service';
import { slideFocusChanged } from '../../../../../shared/store/slide/slide.actions';
import { OverlayMode } from '../../../enums/overlay-mode.interface';
import { CoverTypes } from '../../../models/cover-types.definitions';
import { CoverChooserComponent } from '../../cover-chooser/cover-chooser.component';
import { PlaceholderToolbarComponent } from '../core/toolbar/placeholder-toolbar.component';
import { UrlBasedPlaceholderBaseComponent } from '../core/url-based-placeholder-base.component';

@Component({
  selector: 'rh-image-placeholder',
  templateUrl: './image-placeholder.component.html',
  styleUrls: [
    './image-placeholder.component.scss',
    '../core/placeholder-base.component.scss',
  ],
  standalone: true,
  imports: [NgClass, NgIf, PlaceholderToolbarComponent],
})
export class ImagePlaceholderComponent
  extends UrlBasedPlaceholderBaseComponent
  implements OnDestroy
{
  private dialog = inject(DialogService);
  private store = inject(Store);

  @ViewChild('file') public imageInput!: ElementRef;

  private subscriptions: Subscription = new Subscription();

  @Input() objectFit = 'contain';
  @Input() showToolbar = true;
  @Input() showZoom = true;
  @Input() overlayMode: OverlayMode = OverlayMode.None;

  get enableDelete(): boolean {
    return !!this.url;
  }

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  selectImage() {
    this.store.dispatch(slideFocusChanged({ slideFocused: true }));

    const dialogRef = this.dialog.open(CoverChooserComponent, {
      data: CoverTypes.activeCoverTypes,
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((res: IConfirmationResult) => {
        if (res && res.payload) {
          this.urlChange?.emit(res.payload.templateImageUrl as string);
        }
      }),
    );

    this.store.dispatch(slideFocusChanged({ slideFocused: false }));
  }

  protected readonly OverlayMode = OverlayMode;
}
