<div class="video-placeholder">
  <div class="video-container">
    <video
      #videoElement
      controls
      crossorigin="anonymous"
      class="placeholder-object video-element"
      [ngClass]="{
          'placeholder-object-zoomed': zoomed
        }"
      preload="auto"
      autoplay="autoplay"
      [loop]="loop"
      type="video/mp4"
      [src]="_displayUrl"
      [style.object-fit]="objectFit"
      controlsList="nodownload"
      oncontextmenu="return false"
    ></video>
  </div>
  <rh-placeholder-toolbar
    class="video-toolbar"
    [(zoomed)]="zoomed"
  ></rh-placeholder-toolbar>
</div>
