import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);

  GetEditors(subCategoryName: string): Observable<string[]> {
    return this.http
      .get<
        string[]
      >(`${environment.backendApiUrl}/GetEditors?subCategoryName=${subCategoryName}&code=${environment.apiKey}`)
      .pipe(
        map((response: string[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetApprovers(): Observable<string[]> {
    return this.http
      .get<
        string[]
      >(`${environment.backendApiUrl}/GetApprovers?code=${environment.apiKey}`)
      .pipe(
        map((response: string[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  ChangeLanguageKey(languageKey: string, userId: string) {
    console.log('ChangeLanguageKey');

    return this.http
      .post(
        `${environment.backendApiUrl}/ChangeLanguageKey?code=${environment.apiKey}`,
        { languageKey, userId },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
