import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rh-placeholder-base',
  template: '',
  styleUrls: ['./placeholder-base.component.scss'],
})
export abstract class PlaceholderBaseComponent {
  @Input()
  public editMode = false;

  @Input()
  public showZoomButton = false;

  private _zoomed = false;

  @Input()
  public get zoomed(): boolean {
    return this._zoomed;
  }

  public set zoomed(val: boolean) {
    if (this._zoomed !== val) {
      this._zoomed = val;
      this.onZoomChanged(this._zoomed);
      if (this.zoomedChange) {
        this.zoomedChange.emit(this._zoomed);
      }
    }
  }

  @Output() public zoomedChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public toggleZoom() {
    this.zoomed = !this.zoomed;
    this.zoomedChange?.emit(this.zoomed);
  }

  public onZoomChanged(val: boolean) {
    // Will be overridden in derived classes for
    // special processing depending on the used controls
    this.zoomed = val;
    this.zoomedChange?.emit(val);
  }
}
