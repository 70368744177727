<div class="mediapreview-modal-container">
  <div class="modal-toolbar">
    <div class="close-button" (click)="close()">
      <img class="close-icon" src="./assets/img/icons/close.svg" />
    </div>
  </div>
  <div class="preview">
    @if (isVideo) {
      <video
        #videoElement
        [controls]="true"
        crossorigin="anonymous"
        class="video"
        preload="auto"
        [autoplay]="true"
        [loop]="true"
        type="video/mp4"
        [src]="url"
        [style.objectFit]="'cover'"
      ></video>
    } @else {
      <div
        class="image"
        [ngStyle]="{
          'background-image': 'url(' + url + ')',
        }"
      ></div>
    }
  </div>
</div>
