import { OverlayModule } from '@angular/cdk/overlay';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withDisabledInitialNavigation,
  withHashLocation,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { AuthHttpInterceptor, provideAuth0 } from '@auth0/auth0-angular';
import { LoadingModule } from '@myreishauer/loading';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore, Store } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DragulaModule } from 'ng2-dragula';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { ErrorHandlerService } from './core/services/error-handling/error-handler.service';
import { PermissionsServiceMock } from './core/services/permissions/mocks/permissions.service.mock';
import { PermissionsService } from './core/services/permissions/permissions.service';
import { CommunicationService } from './shared/services/communication/communication.service';
import {
  loadCategories,
  syncCategories,
} from './shared/store/category/category.actions';
import { CategoryEffects } from './shared/store/category/category.effects';
import { categoryReducer } from './shared/store/category/category.reducer';
import { coreReducer } from './shared/store/core/core.reducer';
import { CourseEffects } from './shared/store/course/course.effects';
import { courseReducer } from './shared/store/course/course.reducer';
import { loadLanguages } from './shared/store/language/language.actions';
import { LanguageEffects } from './shared/store/language/language.effects';
import { languageReducer } from './shared/store/language/language.reducer';
import { MediaEffects } from './shared/store/media/media.effects';
import { mediaReducer } from './shared/store/media/media.reducer';
import { loadPermissions } from './shared/store/permissions/permissions.actions';
import { PermissionsEffects } from './shared/store/permissions/permissions.effects';
import { permissionsReducer } from './shared/store/permissions/permissions.reducer';
import { SlideEffects } from './shared/store/slide/slide.effects';
import { slideReducer } from './shared/store/slide/slide.reducer';
import { loadUser } from './shared/store/user/user.actions';
import { UserEffects } from './shared/store/user/user.effects';
import { userReducer } from './shared/store/user/user.reducer';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAuth0({
      domain: environment.domain,
      clientId: environment.clientId,
      authorizationParams: {
        redirect_uri: environment.redirectUri,
        audience: environment.audience,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.backendApiUrl}/*`,
            tokenOptions: {
              authorizationParams: {
                audience: environment.audience,
              },
            },
          },
        ],
      },
    }),
    provideServiceWorker('sw.js', {
      registrationStrategy: 'registerImmediately',
      scope: '/',
    }),
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      LoadingModule,
      OverlayModule,
      DragulaModule.forRoot(),
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [Store],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: CommunicationService) => {
        return () => {
          return service;
        };
      },
      multi: true,
      deps: [CommunicationService],
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: PermissionsService,
      useClass:
        environment.mockBackendServices === 'yes'
          ? PermissionsServiceMock
          : PermissionsService,
    },
    isInIframe()
      ? provideRouter(
          routes,
          withHashLocation(),
          withDisabledInitialNavigation(),
        )
      : provideRouter(routes, withHashLocation()),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideStore(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      },
    ),
    provideState('category', categoryReducer),
    provideState('core', coreReducer),
    provideState('course', courseReducer),
    provideState('language', languageReducer),
    provideState('media', mediaReducer),
    provideState('permissions', permissionsReducer),
    provideState('slide', slideReducer),
    provideState('user', userReducer),
    provideEffects([
      CategoryEffects,
      CourseEffects,
      LanguageEffects,
      MediaEffects,
      PermissionsEffects,
      SlideEffects,
      UserEffects,
    ]),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true,
    }),
  ],
};

function isInIframe() {
  return window !== window.parent && !window.opener;
}

export function initializeApp(store: Store) {
  return () => {
    store.dispatch(loadPermissions());
    store.dispatch(loadUser());
    store.dispatch(loadCategories());
    store.dispatch(syncCategories());
    store.dispatch(loadLanguages());
  };
}
