<div class="two-images-right-template-container top-bottom-padding-template">
  <div class="content-container">
    <div class="left-side">
      <rh-plaintext-placeholder
        class="main-title"
        [fontSize]="'var(--font-size-title-smaller)'"
        [lineHeight]="'1em'"
        [fontColor]="'#254FAA'"
        [text]="title"
        [editMode]="editMode"
        (textChange)="onTitleChange($event)"
      ></rh-plaintext-placeholder>
      <rh-rich-text-placeholder
        class="description-control"
        [html]="html"
        [editMode]="editMode"
        (contentChange)="onContentChange($event)"
        >
      </rh-rich-text-placeholder>
    </div>
    <div class="right-side">
      <div class="image-container">
        <rh-media-placeholder
          class="image-placeholder"
          [urlImage]="urlImageTop"
          [urlVideo]="activatedUrlVideoTop"
          [editMode]="editMode"
          [showZoomButton]="false"
          (zoomedChange)="onZoomedChanged($event)"
          placeholderCode="top"
          (urlImageChange)="onUrlImageTopChange($event)"
          (urlVideoChange)="onUrlVideoTopChange($event)"
        ></rh-media-placeholder>
        <rh-plaintext-placeholder
          class="footnote"
          [fontSize]="'var(--font-size-small-text)'"
          [lineHeight]="'1em'"
          [fontColor]="'#4f4d4d'"
          [text]="footnoteTop"
          [uppercase]="false"
          [editMode]="editMode"
          (textChange)="onFootnoteTopChange($event)"
        ></rh-plaintext-placeholder>
      </div>
      <div class="image-container">
        <rh-media-placeholder
          class="image-placeholder"
          [urlImage]="urlImageBottom"
          [urlVideo]="activatedUrlVideoBottom"
          [editMode]="editMode"
          [showZoomButton]="false"
          (zoomedChange)="onZoomedChanged($event)"
          placeholderCode="bottom"
          (urlImageChange)="onUrlImageBottomChange($event)"
          (urlVideoChange)="onUrlVideoBottomChange($event)"
        ></rh-media-placeholder>
        <rh-plaintext-placeholder
          class="footnote"
          [fontSize]="'var(--font-size-small-text)'"
          [lineHeight]="'1em'"
          [fontColor]="'#4f4d4d'"
          [text]="footnoteBottom"
          [uppercase]="false"
          [editMode]="editMode"
          (textChange)="onFootnoteBottomChange($event)"
        ></rh-plaintext-placeholder>
      </div>
    </div>
  </div>
  @if (zoomed) {
    <div class="zoom-backdrop"></div>
  }
</div>
