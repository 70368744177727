import { createAction, props } from '@ngrx/store';
import { Category } from '../../../core/models/category.model';

export const syncCategories = createAction('[Category] Sync Categories');

export const syncCategoriesSuccess = createAction(
  '[Category] Sync Categories Success',
);

export const syncCategoriesFailure = createAction(
  '[Category] Sync Categories Failure',
  props<{ error: string }>(),
);

export const loadCategories = createAction('[Category] Load Categories Data');

export const loadCategoriesSuccess = createAction(
  '[Category] Load Categories Success',
  props<{ categories: Category[] }>(),
);

export const loadCategoriesFailure = createAction(
  '[Category] Load Categories Failure',
  props<{ error: string }>(),
);

export const saveCategory = createAction(
  '[Category] Save Category',
  props<{ category: Category }>(),
);

export const saveCategorySuccess = createAction(
  '[Category] Save Category Success',
);

export const saveCategoryFailure = createAction(
  '[Category] Save Category Failure',
  props<{ error: string }>(),
);

export const deleteCategory = createAction(
  '[Category] Delete Category',
  props<{ id: number }>(),
);

export const deleteCategorySuccess = createAction(
  '[Category] Delete Category Success',
);

export const deleteCategoryFailure = createAction(
  '[Category] Delete Category Failure',
  props<{ error: string }>(),
);

export const deleteSubcategory = createAction(
  '[Subcategory] Delete Subcategory',
  props<{ id: number }>(),
);

export const deleteSubcategorySuccess = createAction(
  '[Subcategory] Delete Subcategory Success',
);

export const deleteSubcategoryFailure = createAction(
  '[Subcategory] Delete Subcategory Failure',
  props<{ error: string }>(),
);

export const setSelectedCategory = createAction(
  '[Category] Set selected Category',
  props<{ selectedCategory: string }>(),
);
