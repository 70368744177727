import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'rh-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: true,
    imports: [
    NgClass,
    TranslateModule
],
})
export class ButtonComponent {
  @Input()
  public type = 'button';

  @Input()
  public textColor = 'unset';

  @Input()
  public naturalMode = false;

  @Input()
  public text = '?';

  @Input()
  public disabled = false;

  @Input()
  public darkMode = false;

  @Input()
  public redMode = false;

  @Input() imageUrl!: string;

  @Output()
  public buttonClick: EventEmitter<void> = new EventEmitter();

  public handleClick(): void {
    if (this.buttonClick) {
      this.buttonClick.emit();
    }
  }
}
