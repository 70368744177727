import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CategoryState } from './category.state';

export const selectCategoryState =
  createFeatureSelector<CategoryState>('category');

export const selectCategories = createSelector(
  selectCategoryState,
  (state) => state.categories,
);

export const selectSidebarCategory = createSelector(
  selectCategoryState,
  (state) => state.sidebarCategory,
);

export const selectIsCategoryLoading = createSelector(
  selectCategoryState,
  (state) => state.isLoading,
);

export const selectCategoryError = createSelector(
  selectCategoryState,
  (state) => state.error || '',
);
