import { createReducer, on } from '@ngrx/store';
import {
  deleteCategory,
  deleteCategoryFailure,
  deleteCategorySuccess,
  deleteSubcategory,
  deleteSubcategoryFailure,
  deleteSubcategorySuccess,
  loadCategories,
  loadCategoriesFailure,
  loadCategoriesSuccess,
  saveCategory,
  saveCategoryFailure,
  saveCategorySuccess,
  setSelectedCategory,
  syncCategories,
  syncCategoriesFailure,
  syncCategoriesSuccess,
} from './category.actions';
import { initialState } from './category.state';

export const categoryReducer = createReducer(
  initialState,
  on(syncCategories, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
  })),
  on(syncCategoriesSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: undefined,
  })),
  on(syncCategoriesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(deleteCategory, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
  })),
  on(deleteCategorySuccess, (state) => ({
    ...state,
    isLoading: false,
    error: undefined,
  })),
  on(deleteCategoryFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(loadCategories, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
  })),
  on(loadCategoriesSuccess, (state, { categories }) => ({
    ...state,
    isLoading: false,
    categories,
  })),
  on(loadCategoriesFailure, (state, { error }) => ({
    ...state,
    categories: null,
    isLoading: false,
    error,
  })),
  on(saveCategory, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(saveCategorySuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(saveCategoryFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(deleteSubcategory, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
  })),
  on(deleteSubcategorySuccess, (state) => ({
    ...state,
    isLoading: false,
    error: undefined,
  })),
  on(deleteSubcategoryFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(setSelectedCategory, (state, { selectedCategory }) => ({
    ...state,
    sidebarCategory: selectedCategory,
  })),
);
