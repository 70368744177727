import { Permission } from '../../../core/models/permission.model';

export interface PermissionsState {
  permissions?: Permission | null;
  error?: string;
}

export const initialState: PermissionsState = {
  permissions: undefined,
};
