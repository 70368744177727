import { createReducer, on } from '@ngrx/store';
import {
  resetSlideState,
  saveTemporarySlideAssetProgress,
  saveTemporarySlideAssetUrlUpdate,
  slideFocusChanged,
} from './slide.actions';
import { initialState } from './slide.state';

export const slideReducer = createReducer(
  initialState,
  on(saveTemporarySlideAssetProgress, (state, progressUpdate) => ({
    ...state,
    urlUpdate: undefined,
    progressUpdate,
  })),
  on(saveTemporarySlideAssetUrlUpdate, (state, urlUpdate) => ({
    ...state,
    urlUpdate,
    progressUpdate: undefined,
  })),
  on(resetSlideState, (state) => ({
    ...state,
    progressUpdate: undefined,
    urlUpdate: undefined,
    duplicateSuccess: undefined,
  })),
  on(slideFocusChanged, (state, { slideFocused }) => ({
    ...state,
    slideFocused,
  })),
);
