import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Language } from '../../../core/models/language.model';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private http = inject(HttpClient);

  GetLanguages(): Observable<Language[]> {
    return this.http
      .get<
        Language[]
      >(`${environment.backendApiUrl}/GetLanguages?code=${environment.apiKey}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
