import { Component, Input } from '@angular/core';

@Component({
    selector: 'rh-fab',
    templateUrl: './fab.component.html',
    styleUrls: ['./fab.component.scss'],
    standalone: true,
})
export class EditFabComponent {
  @Input() public style = 'fab-icon';
  @Input() public imageUrl!: string;
}
