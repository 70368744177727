import { Asset } from '../../../core/models/asset.model';

export interface SlideState {
  progressUpdate?: IProgressUpdate;
  urlUpdate?: IUrlUpdate;
  assets?: Asset[];
  slideFocused?: boolean;
}

export const initialState: SlideState = {};

export interface IProgressUpdate {
  progress?: number;
  placeholderCode: string;
}

export interface IUrlUpdate {
  urls: string[];
  placeholderCode: string;
}
