import { createReducer, on } from '@ngrx/store';
import { setLoadingActive, setLoadingInactive } from './core.actions';
import { initialState } from './core.state';

export const coreReducer = createReducer(
  initialState,
  on(setLoadingActive, (state) => ({
    ...state,
    loadingOrSaving: true,
  })),
  on(setLoadingInactive, (state) => ({
    ...state,
    loadingOrSaving: false,
  })),
);
