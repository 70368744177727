<div class="bg-white w-full h-full">
  <div class="relative w-full h-full">
    <div class="absolute flex flex-col w-full h-full">
      <rh-media-placeholder
        class="h-full"
        [urlImage]="urlImage"
        [urlVideo]="activatedUrlVideo"
        [editMode]="editMode"
        [objectFit]="'cover'"
        (zoomedChange)="onZoomedChanged($event)"
        (urlImageChange)="onUrlImageChange($event)"
        (urlVideoChange)="onUrlVideoChange($event)"
      ></rh-media-placeholder>
    </div>
    <div class="flex w-1/3 z-10 flex-col pl-[60px] pt-20 gap-[60px]">
      <rh-plaintext-placeholder
        class="z-10"
        [fontSize]="'var(--font-size-title-smaller)'"
        [lineHeight]="'var(--font-size-title-smaller)'"
        [fontColor]="'#93aad4'"
        [text]="title"
        [editMode]="editMode"
        (textChange)="onTitleChange($event)"
      ></rh-plaintext-placeholder>
      <rh-rich-text-placeholder
        class="text-left z-10"
        [html]="html"
        [editMode]="editMode"
        (contentChange)="onContentChange($event)"
        defaultTextStyle="font-family: Helvetica Neue Thin; font-size: 24px; line-height: 1.6em; color: #ffffff;"
        >
      </rh-rich-text-placeholder>
    </div>
  </div>
  @if (zoomed) {
    <div class="zoom-backdrop"></div>
  }
</div>
