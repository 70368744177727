export enum TemplateName {
  COVER_PAGE_TEMPLATE_COMPONENT = 'CoverPageTemplateComponent',
  IMAGE_FULL_WIDTH_TEMPLATE_COMPONENT = 'ImageFullWidthTemplateComponent',
  IMAGE_LEFT_PLOTLY_RIGHT_TEMPLATE_COMPONENT = 'ImageLeftPlotlyRightComponent',
  IMAGE_LEFT_RIGHT_TEMPLATE_COMPONENT = 'ImageLeftRightTemplateComponent',
  IMAGE_RIGHT_TEMPLATE_COMPONENT = 'ImageRightTemplateComponent',
  PLOTLY_RIGHT_TEMPLATE_COMPONENT = 'PlotlyRightTemplateComponent',
  FULL_WIDTH_TEXT_TEMPLATE_COMPONENT = 'FullWidthTextTemplateComponent',
  TEXT_LEFT_IMAGE_RIGHT_TEMPLATE_COMPONENT = 'TextLeftImageRightTemplateComponent',
  TITLE_PAGE_TEMPLATE_COMPONENT = 'TitlePageTemplateComponent',
  GROUP_HEADINGS_TEMPLATE_COMPONENT = 'GroupHeadingsTemplateComponent',
  TWO_IMAGES_RIGHT_TEMPLATE_COMPONENT = 'TwoImagesRightTemplateComponent',
  FOUR_IMAGES_RIGHT_TEMPLATE_COMPONENT = 'FourImagesRightTemplateComponent',
  IMAGE_FULLSCREEN_TEMPLATE_COMPONENT = 'ImageFullscreenTemplateComponent',
}
