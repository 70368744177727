import { createReducer, on } from '@ngrx/store';
import {
  loadPermissionsFailure,
  loadPermissionsSuccess,
} from './permissions.actions';
import { initialState } from './permissions.state';

export const permissionsReducer = createReducer(
  initialState,
  on(loadPermissionsSuccess, (state, { permissions }) => ({
    ...state,
    permissions,
    error: undefined,
  })),
  on(loadPermissionsFailure, (state, { error }) => ({
    ...state,
    permissions: null,
    error,
  })),
);
