<main class="h-screen" id="main">
  <router-outlet></router-outlet>
  @let permissions = permissions$ | async;
  @let isLoadingOrSaving = isLoadingOrSaving$ | async;
  @if (isLoadingOrSaving || permissions === undefined) {
    <rh-loading class="loading-spinner-container"></rh-loading>
  } @else if (permissions === null) {
    <div
      class="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800"
      role="alert"
    >
      {{ 'overview.noLicense' | translate }}
    </div>
  }
</main>
