import { inject } from '@angular/core';

import { CanActivateFn } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter, of, switchMap } from 'rxjs';
import { selectPermissions } from '../../shared/store/permissions/permissions.selectors';

export const adminGuard: CanActivateFn = () => {
  return inject(Store).pipe(
    select(selectPermissions),
    filter((loaded) => !!loaded),
    switchMap((value) => {
      const roles = value?.roles.filter(
        (r) => r.key.indexOf('elearning_admin') > -1,
      );
      return of(roles?.length >= 1);
    }),
  );
};
