<div class="four-images-right-template-container top-bottom-padding-template">
  <div class="content-container">
    <div class="left-side">
      <rh-plaintext-placeholder
        class="main-title"
        [fontSize]="'var(--font-size-title-smaller)'"
        [lineHeight]="'1em'"
        [fontColor]="'#254FAA'"
        [text]="title"
        [editMode]="editMode"
        (textChange)="onTitleChange($event)"
      ></rh-plaintext-placeholder>
      <rh-rich-text-placeholder
        class="description-control"
        [html]="html"
        [editMode]="editMode"
        (contentChange)="onContentChange($event)"
        >
      </rh-rich-text-placeholder>
    </div>
    <div class="right-side">
      <div class="images-row">
        <div class="image-container">
          <rh-media-placeholder
            class="image-placeholder"
            [urlImage]="urlImageTopLeft"
            [urlVideo]="activatedUrlVideoTopLeft"
            [editMode]="editMode"
            [showZoomButton]="false"
            (zoomedChange)="onZoomedChanged($event)"
            placeholderCode="topLeft"
            (urlImageChange)="onUrlImageTopLeftChange($event)"
            (urlVideoChange)="onUrlVideoTopLeftChange($event)"
          ></rh-media-placeholder>
          <rh-plaintext-placeholder
            class="footnote"
            [fontSize]="'var(--font-size-small-text)'"
            [lineHeight]="'1em'"
            [fontColor]="'#4f4d4d'"
            [text]="footnoteTopLeft"
            [uppercase]="false"
            [editMode]="editMode"
            (textChange)="onFootnoteTopLeftChange($event)"
          ></rh-plaintext-placeholder>
        </div>
        <div class="image-container">
          <rh-media-placeholder
            class="image-placeholder"
            [urlImage]="urlImageTopRight"
            [urlVideo]="activatedUrlVideoTopRight"
            [editMode]="editMode"
            [showZoomButton]="false"
            (zoomedChange)="onZoomedChanged($event)"
            placeholderCode="topRight"
            (urlImageChange)="onUrlImageTopRightChange($event)"
            (urlVideoChange)="onUrlVideoTopRightChange($event)"
          ></rh-media-placeholder>
          <rh-plaintext-placeholder
            class="footnote"
            [fontSize]="'var(--font-size-small-text)'"
            [lineHeight]="'1em'"
            [fontColor]="'#4f4d4d'"
            [text]="footnoteTopRight"
            [uppercase]="false"
            [editMode]="editMode"
            (textChange)="onFootnoteTopRightChange($event)"
          ></rh-plaintext-placeholder>
        </div>
      </div>
      <div class="images-row">
        <div class="image-container">
          <rh-media-placeholder
            class="image-placeholder"
            [urlImage]="urlImageBottomLeft"
            [urlVideo]="activatedUrlVideoBottomLeft"
            [editMode]="editMode"
            [showZoomButton]="false"
            (zoomedChange)="onZoomedChanged($event)"
            placeholderCode="bottomLeft"
            (urlImageChange)="onUrlImageBottomLeftChange($event)"
            (urlVideoChange)="onUrlVideoBottomLeftChange($event)"
          ></rh-media-placeholder>
          <rh-plaintext-placeholder
            class="footnote"
            [fontSize]="'var(--font-size-small-text)'"
            [lineHeight]="'1em'"
            [fontColor]="'#4f4d4d'"
            [text]="footnoteBottomLeft"
            [uppercase]="false"
            [editMode]="editMode"
            (textChange)="onFootnoteBottomLeftChange($event)"
          ></rh-plaintext-placeholder>
        </div>
        <div class="image-container">
          <rh-media-placeholder
            class="image-placeholder"
            [urlImage]="urlImageBottomRight"
            [urlVideo]="activatedUrlVideoBottomRight"
            [editMode]="editMode"
            [showZoomButton]="false"
            (zoomedChange)="onZoomedChanged($event)"
            placeholderCode="bottomRight"
            (urlImageChange)="onUrlImageBottomRightChange($event)"
            (urlVideoChange)="onUrlVideoBottomRightChange($event)"
          ></rh-media-placeholder>
          <rh-plaintext-placeholder
            class="footnote"
            [fontSize]="'var(--font-size-small-text)'"
            [lineHeight]="'1em'"
            [fontColor]="'#4f4d4d'"
            [text]="footnoteBottomRight"
            [uppercase]="false"
            [editMode]="editMode"
            (textChange)="onFootnoteBottomRightChange($event)"
          ></rh-plaintext-placeholder>
        </div>
      </div>
    </div>
  </div>
  @if (zoomed) {
    <div class="zoom-backdrop"></div>
  }
</div>
