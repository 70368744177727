import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';


@Component({
    selector: 'rh-confirm-cancel-header',
    templateUrl: './confirm-cancel-header.component.html',
    styleUrls: ['./confirm-cancel-header.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class ConfirmCancelHeaderComponent {
  @Input() public title!: string;
  @Input() public text!: string;
}
