<div class="group-headings-template-container">
  <div class="content-container">
    <div class="content-frame">
      <div class="row-container">
        <div class="section-container">
          <rh-plaintext-placeholder
            class="group-headings-number"
            [fontSize]="'var(--font-size-large-title)'"
            [lineHeight]="'1em'"
            [fontColor]="'#8190b1'"
            [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
            [text]="headingNumbers[placeholderCodes.GROUP_HEADINGS_NUMBER_1]"
            [editMode]="editMode"
            [textAlign]="'right'"
            (textChange)="
              onNumberChange(placeholderCodes.GROUP_HEADINGS_NUMBER_1, $event)
            "
          ></rh-plaintext-placeholder>

          <div class="main-title">
            <rh-plaintext-placeholder
              [fontSize]="'var(--font-size-subheading)'"
              [lineHeight]="'1em'"
              [fontColor]="'#FFFFFF'"
              [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
              [text]="headingTitles[placeholderCodes.GROUP_HEADINGS_TITLE_1]"
              [editMode]="editMode"
              (textChange)="
                onTitleChange(placeholderCodes.GROUP_HEADINGS_TITLE_1, $event)
              "
            ></rh-plaintext-placeholder>

            <rh-plaintext-placeholder
              class="sub-title"
              [fontSize]="'var(--font-size-small-text)'"
              [lineHeight]="'1.5em'"
              [fontColor]="'#a2bef3'"
              [text]="
                headingSubTitles[placeholderCodes.GROUP_HEADINGS_SUB_TITLE_1]
              "
              [editMode]="editMode"
              [uppercase]="false"
              (textChange)="
                onSubTitleChange(
                  placeholderCodes.GROUP_HEADINGS_SUB_TITLE_1,
                  $event
                )
              "
            ></rh-plaintext-placeholder>
          </div>
        </div>
        <div class="section-container">
          <rh-plaintext-placeholder
            class="group-headings-number"
            [fontSize]="'var(--font-size-large-title)'"
            [lineHeight]="'1em'"
            [fontColor]="'#8190b1'"
            [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
            [text]="headingNumbers[placeholderCodes.GROUP_HEADINGS_NUMBER_2]"
            [editMode]="editMode"
            [textAlign]="'right'"
            (textChange)="
              onNumberChange(placeholderCodes.GROUP_HEADINGS_NUMBER_2, $event)
            "
          ></rh-plaintext-placeholder>

          <div class="main-title">
            <rh-plaintext-placeholder
              [fontSize]="'var(--font-size-subheading)'"
              [lineHeight]="'1em'"
              [fontColor]="'#FFFFFF'"
              [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
              [text]="headingTitles[placeholderCodes.GROUP_HEADINGS_TITLE_2]"
              [editMode]="editMode"
              (textChange)="
                onTitleChange(placeholderCodes.GROUP_HEADINGS_TITLE_2, $event)
              "
            ></rh-plaintext-placeholder>

            <rh-plaintext-placeholder
              class="sub-title"
              [fontSize]="'var(--font-size-small-text)'"
              [lineHeight]="'1.5em'"
              [fontColor]="'#a2bef3'"
              [text]="
                headingSubTitles[placeholderCodes.GROUP_HEADINGS_SUB_TITLE_2]
              "
              [editMode]="editMode"
              [uppercase]="false"
              (textChange)="
                onSubTitleChange(
                  placeholderCodes.GROUP_HEADINGS_SUB_TITLE_2,
                  $event
                )
              "
            ></rh-plaintext-placeholder>
          </div>
        </div>
      </div>
      <div class="row-container">
        <div class="section-container">
          <rh-plaintext-placeholder
            class="group-headings-number"
            [fontSize]="'var(--font-size-large-title)'"
            [lineHeight]="'1em'"
            [fontColor]="'#8190b1'"
            [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
            [text]="headingNumbers[placeholderCodes.GROUP_HEADINGS_NUMBER_3]"
            [editMode]="editMode"
            [textAlign]="'right'"
            (textChange)="
              onNumberChange(placeholderCodes.GROUP_HEADINGS_NUMBER_3, $event)
            "
          ></rh-plaintext-placeholder>

          <div class="main-title">
            <rh-plaintext-placeholder
              [fontSize]="'var(--font-size-subheading)'"
              [lineHeight]="'1em'"
              [fontColor]="'#FFFFFF'"
              [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
              [text]="headingTitles[placeholderCodes.GROUP_HEADINGS_TITLE_3]"
              [editMode]="editMode"
              (textChange)="
                onTitleChange(placeholderCodes.GROUP_HEADINGS_TITLE_3, $event)
              "
            ></rh-plaintext-placeholder>

            <rh-plaintext-placeholder
              class="sub-title"
              [fontSize]="'var(--font-size-small-text)'"
              [lineHeight]="'1.5em'"
              [fontColor]="'#a2bef3'"
              [text]="
                headingSubTitles[placeholderCodes.GROUP_HEADINGS_SUB_TITLE_3]
              "
              [editMode]="editMode"
              [uppercase]="false"
              (textChange)="
                onSubTitleChange(
                  placeholderCodes.GROUP_HEADINGS_SUB_TITLE_3,
                  $event
                )
              "
            ></rh-plaintext-placeholder>
          </div>
        </div>
        <div class="section-container">
          <rh-plaintext-placeholder
            class="group-headings-number"
            [fontSize]="'var(--font-size-large-title)'"
            [lineHeight]="'1em'"
            [fontColor]="'#8190b1'"
            [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
            [text]="headingNumbers[placeholderCodes.GROUP_HEADINGS_NUMBER_4]"
            [editMode]="editMode"
            [textAlign]="'right'"
            (textChange)="
              onNumberChange(placeholderCodes.GROUP_HEADINGS_NUMBER_4, $event)
            "
          ></rh-plaintext-placeholder>

          <div class="main-title">
            <rh-plaintext-placeholder
              [fontSize]="'var(--font-size-subheading)'"
              [lineHeight]="'1em'"
              [fontColor]="'#FFFFFF'"
              [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
              [text]="headingTitles[placeholderCodes.GROUP_HEADINGS_TITLE_4]"
              [editMode]="editMode"
              (textChange)="
                onTitleChange(placeholderCodes.GROUP_HEADINGS_TITLE_4, $event)
              "
            ></rh-plaintext-placeholder>

            <rh-plaintext-placeholder
              class="sub-title"
              [fontSize]="'var(--font-size-small-text)'"
              [lineHeight]="'1.5em'"
              [fontColor]="'#a2bef3'"
              [text]="
                headingSubTitles[placeholderCodes.GROUP_HEADINGS_SUB_TITLE_4]
              "
              [editMode]="editMode"
              [uppercase]="false"
              (textChange)="
                onSubTitleChange(
                  placeholderCodes.GROUP_HEADINGS_SUB_TITLE_4,
                  $event
                )
              "
            ></rh-plaintext-placeholder>
          </div>
        </div>
      </div>
      <div class="row-container">
        <div class="section-container">
          <rh-plaintext-placeholder
            class="group-headings-number"
            [fontSize]="'var(--font-size-large-title)'"
            [lineHeight]="'1em'"
            [fontColor]="'#8190b1'"
            [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
            [text]="headingNumbers[placeholderCodes.GROUP_HEADINGS_NUMBER_5]"
            [editMode]="editMode"
            [textAlign]="'right'"
            (textChange)="
              onNumberChange(placeholderCodes.GROUP_HEADINGS_NUMBER_5, $event)
            "
          ></rh-plaintext-placeholder>

          <div class="main-title">
            <rh-plaintext-placeholder
              [fontSize]="'var(--font-size-subheading)'"
              [lineHeight]="'1em'"
              [fontColor]="'#FFFFFF'"
              [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
              [text]="headingTitles[placeholderCodes.GROUP_HEADINGS_TITLE_5]"
              [editMode]="editMode"
              (textChange)="
                onTitleChange(placeholderCodes.GROUP_HEADINGS_TITLE_5, $event)
              "
            ></rh-plaintext-placeholder>

            <rh-plaintext-placeholder
              class="sub-title"
              [fontSize]="'var(--font-size-small-text)'"
              [lineHeight]="'1.5em'"
              [fontColor]="'#a2bef3'"
              [text]="
                headingSubTitles[placeholderCodes.GROUP_HEADINGS_SUB_TITLE_5]
              "
              [editMode]="editMode"
              [uppercase]="false"
              (textChange)="
                onSubTitleChange(
                  placeholderCodes.GROUP_HEADINGS_SUB_TITLE_5,
                  $event
                )
              "
            ></rh-plaintext-placeholder>
          </div>
        </div>
        <div class="section-container">
          <rh-plaintext-placeholder
            class="group-headings-number"
            [fontSize]="'var(--font-size-large-title)'"
            [lineHeight]="'1em'"
            [fontColor]="'#8190b1'"
            [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
            [text]="headingNumbers[placeholderCodes.GROUP_HEADINGS_NUMBER_6]"
            [editMode]="editMode"
            [textAlign]="'right'"
            (textChange)="
              onNumberChange(placeholderCodes.GROUP_HEADINGS_NUMBER_6, $event)
            "
          ></rh-plaintext-placeholder>

          <div class="main-title">
            <rh-plaintext-placeholder
              [fontSize]="'var(--font-size-subheading)'"
              [lineHeight]="'1em'"
              [fontColor]="'#FFFFFF'"
              [editModeBackgroundColor]="'var(--rh-blue-edit-mode)'"
              [text]="headingTitles[placeholderCodes.GROUP_HEADINGS_TITLE_6]"
              [editMode]="editMode"
              (textChange)="
                onTitleChange(placeholderCodes.GROUP_HEADINGS_TITLE_6, $event)
              "
            ></rh-plaintext-placeholder>

            <rh-plaintext-placeholder
              class="sub-title"
              [fontSize]="'var(--font-size-small-text)'"
              [lineHeight]="'1.5em'"
              [fontColor]="'#a2bef3'"
              [text]="
                headingSubTitles[placeholderCodes.GROUP_HEADINGS_SUB_TITLE_6]
              "
              [editMode]="editMode"
              [uppercase]="false"
              (textChange)="
                onSubTitleChange(
                  placeholderCodes.GROUP_HEADINGS_SUB_TITLE_6,
                  $event
                )
              "
            ></rh-plaintext-placeholder>
          </div>
        </div>
      </div>
      <rh-image-placeholder
        class="image-placeholder"
        [showToolbar]="true"
        [showZoom]="false"
        [url]="url"
        [objectFit]="'cover'"
        [editMode]="editMode"
        (zoomedChange)="onZoomedChanged($event)"
        (urlChange)="onUrlChange($event)"
        [overlayMode]="overlayMode"
      ></rh-image-placeholder>
    </div>
  </div>
  @if (zoomed) {
    <div class="zoom-backdrop"></div>
  }
</div>
