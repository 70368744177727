import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MediaTile } from '../../../core/models/media-tile.model';
import { User } from '../../../core/models/user.model';
import { MediaState } from './media.state';

export const selectMediaState = createFeatureSelector<MediaState>('media');

export const selectMediaAssets = createSelector(selectMediaState, (state) =>
  state.assets.map((a) => {
    return {
      id: a.id,
      title: a.assetName,
      author: a.editor,
      imageUrl: a.assetUrl,
      displayImageUrl: a.assetDisplayUrl,
      mimeType: a.mimeType,
      categoryId: a.categoryId,
      categoryName: a.categoryName,
      uploaded: a.uploaded,
      private: a.private,
    } as MediaTile;
  }),
);

export const selectMediaError = createSelector(
  selectMediaState,
  (state) => state.error,
);

const selectMediaEditors = createSelector(
  selectMediaState,
  (state) => state.editors,
);

export const selectAvailableEditorsAsUsers = createSelector(
  selectMediaEditors,
  (editors) =>
    editors.map((m) => {
      return { name: m, accountName: m } as User;
    }),
);

export const selectTemporaryMediaAssets = createSelector(
  selectMediaState,
  (state) => state.temporaryAssets,
);

export const selectMediaProgress = createSelector(
  selectMediaState,
  (state) => state.progressUpdate,
);
