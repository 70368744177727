import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '../../../core/models/user.model';
import { selectPermissions } from '../permissions/permissions.selectors';
import { UserState } from './user.state';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUser = createSelector(
  selectUserState,
  (state) => state.user,
);

export const selectUserWithCompany = createSelector(
  selectUser,
  selectPermissions,
  (user, permissions) => {
    if (!user || !permissions) {
      return undefined;
    }

    return {
      ...user,
      companyName: permissions.organizationName,
    } as User;
  },
);

export const selectUserLanguage = createSelector(
  selectUserState,
  (state) => state.language,
);

export const selectEditors = createSelector(selectUserState, (state) =>
  state.editors?.map((editor) => {
    return {
      name: editor,
    };
  }),
);

export const selectIsReishauerUser = createSelector(selectUser, (user) => {
  return user?.email.toLowerCase().endsWith('@reishauer.com') || false;
});
