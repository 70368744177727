import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PlaceholderToolbarComponent } from '../core/toolbar/placeholder-toolbar.component';
import { UrlBasedPlaceholderBaseComponent } from '../core/url-based-placeholder-base.component';

@Component({
  selector: 'rh-video-placeholder',
  templateUrl: './video-placeholder.component.html',
  styleUrls: [
    './video-placeholder.component.scss',
    '../core/placeholder-base.component.scss',
  ],
  standalone: true,
  imports: [NgClass, PlaceholderToolbarComponent],
})
export class VideoPlaceholderComponent extends UrlBasedPlaceholderBaseComponent {
  @Input() public objectFit = 'contain';

  @ViewChild('videoElement') public video!: ElementRef;
  @ViewChild('seekBar') public seekBar!: ElementRef;

  @Output() public uploadProgressChange: EventEmitter<number> =
    new EventEmitter<number>();

  public loop = true;

  constructor() {
    super();
  }
}
