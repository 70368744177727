<div
  class="media-placeholder"
  [ngClass]="{
    'edit-mode-background': editMode,
  }"
>
  @if (showImagePlaceholder) {
    <rh-image-placeholder
      class="placeholder-component"
      #imagePlaceholder
      [url]="urlImage"
      [objectFit]="objectFit"
      [editMode]="editMode"
      [showZoomButton]="false"
      [zoomed]="zoomed"
      [showToolbar]="false"
      (urlChange)="onImageUrlChange($event)"
      (click)="toggleZoom()"
    ></rh-image-placeholder>
  }
  @if (showVideoPlaceholder) {
    <rh-video-placeholder
      class="placeholder-component"
      #videoPlaceholder
      [url]="urlVideo"
      [objectFit]="objectFit"
      [editMode]="editMode"
      [showZoomButton]="false"
      (urlChange)="onVideoUrlChange($event)"
    ></rh-video-placeholder>
  }

  <rh-placeholder-toolbar
    class="media-toolbar"
    [editMode]="editMode"
    [enableDelete]="true"
    [showZoom]="showZoomButton"
    [(zoomed)]="zoomed"
    (zoomedChange)="onZoomChanged($event)"
    (contentRemove)="onRemoveMediaUrl()"
    (contentAdd)="selectMedia()"
  ></rh-placeholder-toolbar>
</div>
